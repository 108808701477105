import { Component, EventEmitter, Output, Input, OnChanges } from '@angular/core';

import { ITagLegend, ITag, IUpdateTag } from '../../../store/tags/records-tags.types';
import { TagValues } from '../tags.const';

@Component({
	selector: 'cor-tags-select',
	templateUrl: './tags-select.component.html',
	styleUrls: ['./tags-select.component.scss']
})
export class TagsSelectComponent implements OnChanges {
	@Input() public hideConfirm = false;
	@Input() public tagOptions: ITagLegend[] = [];
	@Input() public tag?: ITag;
	@Output() public updateTags: EventEmitter<IUpdateTag> = new EventEmitter<IUpdateTag>();
	@Output() public closeTags: EventEmitter<void> = new EventEmitter<void>();

	public currentTags: { [key: number]: ITag } = {};
	public tagsState = {};
	public tagValues = TagValues;

	public ngOnChanges(): void {
		this.currentTags = {};

		if (this.tag && this.tag.tagValue) {
			this.currentTags[this.tag.tagValue] = this.tag;
		}

		if (this.tagOptions) {
			this.tagOptions.forEach((tag: ITag) => {
				this.tagsState[tag.tagValue] = !!this.currentTags[tag.tagValue];
			});
		}
	}

	public save(): void {
		this.closeTags.emit();
	}

	public toggleTag(selectedTag: string, selectedTagId: number): void {
		Object.keys(this.tagsState).forEach((tag: string) => this.tagsState[tag] = this.tagsState[selectedTag] ? false : tag === selectedTag);
		this.updateTags.emit({ tag: selectedTag, id: selectedTagId, select: this.tagsState[selectedTag] });
	}
}
