import { ActivatedRoute, Router, NavigationStart, RouterEvent } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { isNil, propOr, pathOr, groupBy } from 'ramda';
import { Store } from '@ngrx/store';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import * as debounce from 'lodash.debounce';

import { IAllRecordsState } from '../../store/all/records-all.types';
import { INotesSaved, INotes } from '../../../records/store/notes/records-notes.types';
import { IOfflineSearch, IAnnotation } from '../../../shared/store/offline/offline.types';
import { IPreviousRoute } from '../../../shared/store/back-button/back-button.types';
import { IRecordsQueryParams, IRecordsParams } from '../../records.types';
import { IRecordsSection, IRecord } from '../../../shared/services/records-base.types';
import { IRecordsState } from '../../store/records.reducers';
import { IResponseError } from '../../../shared/shared.types';
import { ISubNavigation } from '../../store/sub-navigation/records-sub-navigation.types';
import { ITagLegend, IUpdateTag } from '../../store/tags/records-tags.types';
import { IUserPreferences, IUserInfo } from '../../../user/services/user.types';
import { NotesService } from '../../services/notes.service';
import { NotesStoreOverview, NotesFetchById } from '../../../records/store/notes/records-notes.actions';
import { OfflineRecordsService } from '../../services/offline.service';
import { OnlineRecordsService } from '../../services/online.service';
import { RECORDS_FILTERS, FilterValues } from '../../records.const';
import { RecordsClearSections } from '../../store/sections/records-sections.actions';
import { RecordsFetchAll, RecordsClearAll } from '../../store/all/records-all.actions';
import { RecordsFetchSubNavigation, RecordsClearSubNavigation } from '../../store/sub-navigation/records-sub-navigation.actions';
import { selectNotes } from '../../../records/store/notes/records-notes.selectors';
import { selectPreviousRoute } from '../../../shared/store/back-button/back-button.selectors';
import { selectRecordsError } from '../../store/all/records-all.selectors';
import { selectSearchesOffline } from '../../../shared/store/offline/offline.selectors';
import { selectSubNavigation, selectRecordsState } from '../../store/sub-navigation/records-sub-navigation.selectors';
import { selectUserInfo } from '../../../user/store/info/info.selectors';
import { selectUserPreferences } from '../../../user/store/preferences/preferences.selectors';
import { TagsUpdate } from '../../store/tags/records-tags.actions';
import { UIStoreRoute } from '../../../shared/store/shared.actions';
import { WindowService } from '../../../shared/services/window.service';

@Component({
	templateUrl: './overview.page.html',
})
export class RecordsOverviewPage implements OnInit, OnDestroy {
	public error = false;
	public filterValue = FilterValues.AL;
	public loading = false;
	public notes: { [key: string]: INotes } | INotes = {};
	public notesLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public offline = false;
	public offlineNotes: { [key: string]: INotes } = {};
	public openedSection: number;
	public RECORDS_FILTERS = RECORDS_FILTERS;
	public records: IAllRecordsState = null;
	public recordsLoading = false;
	public searchTitle: string = null;
	public sections: IRecordsSection[] = [];
	public standardLimit = 10;
	public tagOptions: ITagLegend[] = [];

	private contactId: number;
	private contactInitials: string;
	private dataFetched = false;
	private offlineSearch: IOfflineSearch;
	private prevQueryParams: object;
	private queryParams: IRecordsQueryParams;
	private quickNotes: INotesSaved[];
	private scrollPosition: number;
	private searchParam: string;
	private tagLegend: string;

	private onDestroy$: Subject<boolean> = new Subject<boolean>();

	private debounceFetchOnlineData: any = debounce(() => this.fetchOnlineData(), 1000);

	constructor(
		private offlineRecordsService: OfflineRecordsService,
		private onlineRecordsService: OnlineRecordsService,
		private route: ActivatedRoute,
		private router: Router,
		private store: Store<IRecordsState>,
		private windowService: WindowService,
	) {
		this.store.select(selectPreviousRoute)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((prevRoute: IPreviousRoute) => {
				this.prevQueryParams = prevRoute.inboxQueryParams;
				this.scrollPosition = prevRoute.scrollPositionRecords;
			});

		this.store.select(selectRecordsState)
			.pipe(
				takeUntil(this.onDestroy$),
				filter((records: IRecordsState) => !isNil(records.all) && !isNil(records.sections) && !this.dataFetched)
			)
			.subscribe(() => {
				this.getOnlineData();
				this.windowService.setScrollPosition(this.scrollPosition);
			});

		this.store.select(selectSubNavigation)
			.pipe(
				takeUntil(this.onDestroy$),
				filter((search: ISubNavigation) => !isNil(search)),
			)
			.subscribe((search: ISubNavigation) => this.searchTitle = propOr('', 'reportTitle', search));

		this.router.events
			.pipe(
				takeUntil(this.onDestroy$),
				filter((e: RouterEvent) => e instanceof NavigationStart),
			)
			.subscribe(() => this.store.dispatch(new UIStoreRoute(this.windowService.getScrollPosition())));

		this.store.select(selectRecordsError)
			.pipe(
				takeUntil(this.onDestroy$),
				filter((error: IResponseError) => !isNil(error)),
			)
			.subscribe(() => {
				this.loading = false;
				this.error = true;
			});

		this.store.select<IUserPreferences>(selectUserPreferences)
			.pipe(
				takeUntil(this.onDestroy$),
				filter((preferences: IUserPreferences) => !isNil(preferences)),
			)
			.subscribe((preferences: IUserPreferences) => {
				this.quickNotes = preferences.savedNotes;
				this.contactInitials = preferences.contactInitials;
				this.tagLegend = preferences.tagSettings.tagLegend;
				this.tagOptions = preferences.tagSettings.tags;
			});

		this.store.select<IUserInfo>(selectUserInfo)
			.pipe(
				takeUntil(this.onDestroy$),
				filter((info: IUserInfo) => !isNil(info)),
			)
			.subscribe((info: IUserInfo) => this.contactId = info.contactId);

		this.store.select<INotes>(selectNotes)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((notes: INotes) => {
				this.notesLoading$.next(false);
				this.notes = notes;
			});

		this.store.select<IOfflineSearch>(selectSearchesOffline)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((offlineSearch: IOfflineSearch) => this.offlineSearch = offlineSearch);
	}

	public ngOnInit(): void {
		this.loading = true;

		combineLatest(this.route.params, this.route.queryParams)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((params: [IRecordsParams, IRecordsQueryParams]) => {
				const filterValue: string = pathOr(null, [1, 'filter'], params);
				const sectionId: number = Number(pathOr(0, [1, 'sectionId'], params));

				this.searchParam = params[0].search;
				this.queryParams = params[1];
				this.openedSection = sectionId;

				this.filterValue = filterValue as FilterValues || FilterValues.AL;

				if (this.searchParam && !this.dataFetched) {
					this.offline = this.offlineSearch && this.offlineSearch.searchOrderId.toString() === this.searchParam;

					if (this.offline) {
						this.getOfflineData();
						this.searchTitle = pathOr('', ['search', 'searchedTrademark'], this.offlineSearch);
					} else {
						this.fetchOnlineData();
						this.store.dispatch(new RecordsFetchSubNavigation({ searchOrderId: this.searchParam }));
					}
				}
			});
	}

	public ngOnDestroy(): void {
		this.loading = true;
		this.error = false;

		this.store.dispatch(new RecordsClearAll());
		this.store.dispatch(new RecordsClearSubNavigation());
		this.store.dispatch(new RecordsClearSections());
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	public filter(value: FilterValues): void {
		this.filterValue = value;

		if (this.offline) {
			this.getOfflineData();
		} else {
			this.getOnlineData();
		}
		this.setQueryParams(Number(propOr(0, 'sectionId', this.queryParams)));
	}

	public onSaveNotes(formValue: Array<string | boolean>, recordId: string): void {
		const noteList: INotes = NotesService.mapNotes(formValue, this.quickNotes, recordId, this.contactInitials, this.contactId.toString());

		if (this.offline) {
			this.offlineRecordsService.addNotes(noteList)
				.then(() => {
					this.notes = {
						[recordId]: [
							...this.notes[recordId],
							...noteList,
						],
					};
				});
		} else {
			this.notesLoading$.next(true);
			this.store.dispatch(new NotesStoreOverview({
				searchOrderId: Number(this.searchParam),
				noteList,
			}));
			this.debounceFetchOnlineData();
		}
	}

	public onOpenNotes(recordId: string) {
		if (this.offline) {
			this.offlineRecordsService.getNotes(recordId)
				.then((notes: INotes) => {
					this.notes = {
						[recordId]: notes,
					};
				});
		} else {
			this.store.dispatch(new NotesFetchById(recordId));
		}
	}

	public onUpdateTags(updateTag: IUpdateTag, recordId: string): void {
		const select = updateTag.select;
		const tag = {
			recordId,
			tagOption: select ? this.tagOptions[updateTag.id].tagOption.toString() : null,
			tagValue: select ? this.tagOptions[updateTag.id].tagValue : null,
			tagLegend: select ? this.tagLegend : null,
			contactInitials: this.contactInitials,
			contactId: this.contactId.toString(),
		};

		if (this.offline) {
			this.offlineRecordsService.updateTag(recordId, tag, select);
		} else {
			this.store.dispatch(new TagsUpdate({
				searchOrderId: Number(this.searchParam),
				tagList: [tag]
			}));
			this.debounceFetchOnlineData();
		}
	}

	public navigate(): void {
		this.router.navigate(['../inbox'], { queryParams: this.prevQueryParams });
	}

	public setQueryParams(section: number = 0): void {
		this.router.navigate([], {
			queryParams: {
				sectionId: section,
				filter: this.filterValue,
			},
		});
	}

	private fetchOnlineData(): void {
		this.store.dispatch(new RecordsFetchAll({
			filterBy: [{
				filterName: 'RecordFilter',
				filterValue: FilterValues.AL,
			}],
			isIncludeSections: true,
			isOffline: true,
			searchOrderId: this.searchParam,
			subSectionNameID: null,
		}));
	}

	private getOnlineData(): void {
		this.getData(this.onlineRecordsService);
	}

	private getOfflineData(): void {
		this.getData(this.offlineRecordsService);
	}

	private getData(service: OnlineRecordsService | OfflineRecordsService): void {
		service.setFilter(this.filterValue);
		service.getSections()
			.then((sections: IRecordsSection[]) => {
				if (!this.dataFetched) {
					this.dataFetched = true;
					this.setQueryParams(Number(propOr(sections[0].subSectionId, 'sectionId', this.queryParams)));
				}

				return Promise.all([
					Promise.resolve(sections),
					Promise.all(sections.map((section: IRecordsSection) => {
						return service.getRecords(section.subSectionId, this.filterValue);
					})),
					service instanceof OfflineRecordsService ? service.getAnnotations() : Promise.resolve(null),
				]);
			})
			.then(([sections, records, annotations]: [IRecordsSection[], IRecord[][], IAnnotation[]]) => {
				this.mapData(sections, records, annotations);
				this.loading = false;
			});
	}

	private mapData(sections: IRecordsSection[], records: IRecord[][], annotations: IAnnotation[]): void {
		this.sections = sections.map((section: IRecordsSection, index: number) => ({
			...section,
			recordCount: records[index].length,
		}));
		this.records = {
			records: records.reduce((acc: IRecord[], rec: IRecord[]) => {
				return acc.concat(rec);
			}, []),
			filter: this.filterValue,
		};
		if (annotations) {
			this.offlineNotes = groupBy(
				(annotation: IAnnotation): string => annotation.value.recordId,
				annotations.filter((annotation: IAnnotation) => annotation.type === 'note')
			);
		}
	}
}
