import { Injectable } from '@angular/core';
import { path } from 'ramda';

import { IAnnotation } from '../../shared/store/offline/offline.types';
import { IRecord, IRecordsSection, IRecordDetail, IRecordNote } from '../../shared/services/records-base.types';
import { IRecordTag } from '../records.types';
import { OfflineRecordDetailService } from '../../indexed-db/services/offline/full-record/record-detail.service';
import { OfflineRecordsAnnotationsService } from '../../indexed-db/services/offline/annotations/records-annotations.service';
import { OfflineRecordsListService } from '../../indexed-db/services/offline/browse-list/records-list.service';
import { OfflineRecordsMetaService } from '../../indexed-db/services/offline/browse-list/records-meta.service';
import { OfflineRecordsNotesService } from '../../indexed-db/services/offline/annotations/records-notes.service';
import { OfflineRecordsSectionsService } from '../../indexed-db/services/offline/browse-list/records-sections.service';
import { RECORDS_FILTERS, FilterValues } from '../records.const';

@Injectable()
export class OfflineRecordsService {
	constructor(
		private recordDetailService: OfflineRecordDetailService,
		private recordsAnnotationsService: OfflineRecordsAnnotationsService,
		private recordsListService: OfflineRecordsListService,
		private recordsMetaService: OfflineRecordsMetaService,
		private recordsNotesService: OfflineRecordsNotesService,
		private recordsSectionsService: OfflineRecordsSectionsService,
	) {}

	public getSections(): Promise<IRecordsSection[]> {
		return this.recordsSectionsService.getAll();
	}

	public getRecords(subSectionNameID: number, filterValue: string = ''): Promise<IRecord[]> {
		const filter: any = this.getFilter(filterValue as FilterValues);

		return this.recordsListService.getWhere({ subSectionNameID, ...filter.filterObject })
			.then((records: IRecord[]) => {
				if (!filter.filterFunction) {
					return records;
				}

				return records.filter(filter.filterFunction);
			});
	}

	public getRecord(srRecordId: string): Promise<IRecordDetail> {
		return this.recordDetailService.getOne({
			'fullRecord.srRecordId': srRecordId,
		});
	}

	public getNotes(recordId: string): Promise<IRecordNote[]> {
		return this.recordsNotesService.getWhere({ recordId });
	}

	public getAnnotations(): Promise<any[]> {
		return this.recordsAnnotationsService.getAll();
	}

	public addNotes(notes: IRecordNote[]): Promise<number> {
		const now: number = Date.now();

		return this.recordsAnnotationsService.bulkAdd(notes.map((note: IRecordNote) => ({ type: 'note', value: note })))
			.then(() => this.recordsNotesService.bulkAdd(notes.map((note: IRecordNote, index: number) => {
				return {
					...note,
					recordNoteId: `x-${now}-${index}`,
				};
			})))
			.then(() => this.recordsListService.update(notes[0].recordId, { hasNotes: true }));
	}

	public updateTag(srRecordId: string, tag: IRecordTag, select: boolean): Promise<number> {
		const tagAnnotation: IAnnotation = { type: 'tag', value: tag };

		return this.recordsAnnotationsService.getWhere({ 'value.recordId': srRecordId })
			.then((annotations: IAnnotation[]) => {
				if (annotations.length) {
					tagAnnotation.id = path(['0', 'id'], annotations);
				}

				return this.recordsAnnotationsService.put(tagAnnotation);
			})
			.then(() => this.recordsListService.update(srRecordId, { tag: select ? tag : null }))
			.then(() => this.recordDetailService.update(srRecordId, { tag: select ? tag : null }));
	}

	public setFilter(filterValue: FilterValues) {
		this.recordsMetaService.put({ key: 'filter', value: filterValue });
	}

	public getFilters(): any {
		return this.recordsMetaService.getAll();
	}

	private getFilter(filterValue: FilterValues): any {
		const filterRecord: any = RECORDS_FILTERS.find((f: any) => f.value === filterValue);

		if (!filterRecord) {
			return {
				filterObject: {},
				filterFunction: null,
			};
		}

		return filterRecord;
	}
}
