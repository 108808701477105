import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'ramda';

@Pipe({ name: 'kebabCase' })
export class KebabCase implements PipeTransform {
	transform(text: string): string {
		return !isNil(text) ? text.replace(/\s+/g, '-').toLowerCase() : null;
	}
}

