import { Inject, Injectable } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { IScreenSpecs } from './window.types';

@Injectable()
export class WindowService {
	constructor(
		@Inject(DOCUMENT) private document: Document,
		private viewportScroller: ViewportScroller,
	) {}

	public resetDivScroll(selector: string): void {
		this.document.querySelector(selector).scrollTop = 0;
	}

	public getScreenSpecs(): IScreenSpecs {
		const window = this.document.defaultView;

		return {
			width: window.innerHeight,
			height: window.innerWidth,
			devicePixelRatio: window.devicePixelRatio,
		};
	}

	public confirmPopup(message: string): boolean {
		const window = this.document.defaultView;

		return window.confirm(message) ? true : false;
	}

	public getScrollPosition(): number {
		const window = this.document.defaultView;

		return window.scrollY;
	}

	public setScrollPosition(scrollY: number): void {
		setTimeout(() => this.viewportScroller.scrollToPosition([0, scrollY]));
	}

	public toggleBodyScroll() {
		this.document.body.classList.toggle('noscroll');
	}
}
