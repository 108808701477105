import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilter } from '../../shared.types';
import { WindowService } from '../../services/window.service';

@Component({
	selector: 'cor-sub-navigation',
	templateUrl: './sub-navigation.component.html',
	styleUrls: ['./sub-navigation.component.scss']
})
export class SubNavigationComponent {
	@Input() public label: string;
	@Input() public filters: IFilter[] = [];
	@Input() public filterValue: string;
	@Output() public navigate: EventEmitter<void> = new EventEmitter<void>();
	@Output() public filter: EventEmitter<string> = new EventEmitter<string>();

	public open = false;

	constructor(
		private windowService: WindowService,
	) {}

	public navigateBack(): void {
		this.navigate.emit();
	}

	public openFiltersPopup(): void {
		this.windowService.toggleBodyScroll();
		this.open = true;
	}

	public selectValue(value: string): void {
		this.filter.emit(value);
		this.close();
	}

	public close(): void {
		this.windowService.toggleBodyScroll();
		this.open = false;
	}
}
