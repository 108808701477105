import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'cor-input-password',
	templateUrl: './input-password.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputPasswordComponent),
			multi: true,
		},
	],
})
export class InputPasswordComponent implements ControlValueAccessor {
	@Input() label?: string;
	@Input() placeholder?: string;
	@Input() disabled = false;

	public value: string;

	public isVisible = false;
	public currentType = 'password';

	public togglePasswordVisibility(): void {
		this.currentType = !this.isVisible ? 'text' : 'password';
		this.isVisible = !this.isVisible;
	}

	public onChange: (_) => any = () => { };

	public onTouched = () => { };

	public writeValue(value: string): void {
		if (value) {
			this.value = value;
			this.onChange(this.value);
		}
	}

	public registerOnChange(onChange: (_) => any): void {
		this.onChange = onChange;
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
