import { Action } from '@ngrx/store';

import { RecordsAllActionTypes } from './records-all.action-types';
import { IRecordsFetchAllParams, IRecordsPayload } from './records-all.types';
import { IResponseError } from '../../../shared/shared.types';

export class RecordsFetchAll implements Action {
	readonly type = RecordsAllActionTypes.RecordsAllFetch;

	constructor(public payload: IRecordsFetchAllParams) {}
}

export class RecordsFetchAllSuccess implements Action {
	readonly type = RecordsAllActionTypes.RecordsAllFetchSuccess;

	constructor(public payload: IRecordsPayload) {}
}

export class RecordsFetchAllError implements Action {
	readonly type = RecordsAllActionTypes.RecordsAllFetchError;

	constructor(public payload: IResponseError) {}
}

export class RecordsClearAll implements Action {
	readonly type = RecordsAllActionTypes.RecordsAllClear;
}

export type RecordsAllUnion =
	RecordsFetchAll |
	RecordsFetchAllSuccess |
	RecordsFetchAllError |
	RecordsClearAll;
