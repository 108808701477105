import { Action } from '@ngrx/store';
import { IOfflineSearch } from './offline.types';
import { SearchesOfflineTypes } from './offline.action-types';

export class SearchesSetOffline implements Action {
	readonly type = SearchesOfflineTypes.SetOfflineSearch;

	constructor(public payload: IOfflineSearch) {}
}

export class SearchesClearOffline implements Action {
	readonly type = SearchesOfflineTypes.ClearOfflineSearch;

	constructor(public payload?: { failed?: boolean }) {}
}

export type SearchesOfflineUnion =
	SearchesSetOffline |
	SearchesClearOffline;
