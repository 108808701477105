import { IUserPreferences } from '../../services/user.types';
import { PREFERENCES_INITIAL_STATE } from './preferences.initial-state';
import { PreferencesActionTypes } from './preferences.action-types';
import { PreferencesUnion } from './preferences.actions';

export function preferencesReducer(
	state: IUserPreferences = PREFERENCES_INITIAL_STATE,
	action: PreferencesUnion
): IUserPreferences {
	switch (action.type) {
		case PreferencesActionTypes.StoreUserPreferencesSuccess: {
			return action.payload;
		}

		case PreferencesActionTypes.ResetUserPreferences: {
			return PREFERENCES_INITIAL_STATE;
		}

		default: {
			return state;
		}
	}
}
