import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[corStopPropagation]'
})
export class StopPropagationDirective {
	@HostListener('click', ['$event'])

	public onClick(event: MouseEvent): boolean {
		event.stopPropagation();
		return false;
	}
}
