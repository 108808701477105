import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { equals } from "ramda";
import { Observable, Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";

import { WindowService } from "../../shared/services/window.service";
import { checkExpired, checkToken } from "../helpers";
import { IUser } from "../services/auth.types";
import { AuthRefreshUser } from "../store/auth/auth.actions";
import { selectCurrentUser } from "../store/auth/auth.selectors";
import { USER_INITAL_STATE } from "../store/user.reducers";
import { selectUserState } from "../store/user.selectors";
import { IUserState } from "../store/user.types";

export enum TOKEN_STATUS {
	EXPIRED = "EXPIRED",
}

@Injectable()
export class AuthService implements OnDestroy {
	private authBaseUrl = "/cgp.webapi/auth";
	private onDestroy$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private http: HttpClient,
		private store: Store<IUserState>,
		private windowService: WindowService,
		private router: Router
	) {
		this.store
			.select<IUserState>(selectUserState)
			.pipe(
				takeUntil(this.onDestroy$),
				filter((state: IUserState) => equals(state, USER_INITAL_STATE))
			)
			.subscribe(() => this.router.navigate(["/", "login"]));
	}

	public getUser(): Observable<IUser | TOKEN_STATUS> {
		return this.store.select<IUser>(selectCurrentUser).pipe(
			map((user: IUser) => {
				if (checkToken(user) && checkExpired(user)) {
					this.store.dispatch(
						new AuthRefreshUser({
							refreshToken: user.refresh_token,
						})
					);

					return TOKEN_STATUS.EXPIRED;
				}

				return user;
			})
		);
	}

	public login(
		username: string,
		password: string,
		verificationcode: string
	): Observable<IUser> {
		const screenSpecs = this.windowService.getScreenSpecs();

		const body = new HttpParams()
			.set("username", username)
			.set("password", password)
			.set("grant_type", "password")
			.set("width", screenSpecs.width.toString())
			.set("height", screenSpecs.height.toString())
			.set("screenDensity", screenSpecs.devicePixelRatio.toString())
			.set("verificationcode", verificationcode);

		return this.http.post<IUser>(
			`${this.authBaseUrl}/login`,
			body.toString(),
			{
				headers: new HttpHeaders().set(
					"Content-Type",
					"application/x-www-form-urlencoded"
				),
			}
		);
	}

	public refresh(refreshToken: string): Observable<IUser> {
		const body = new HttpParams()
			.set("refresh_token", refreshToken)
			.set("grant_type", "refresh_token");

		return this.http.post<IUser>(
			`${this.authBaseUrl}/login`,
			body.toString(),
			{
				headers: new HttpHeaders().set(
					"Content-Type",
					"application/x-www-form-urlencoded"
				),
			}
		);
	}

	public logout(refreshToken: string): Observable<void> {
		return this.http.get<void>(`${this.authBaseUrl}/logout`, {
			params: {
				refreshtoken: refreshToken,
			},
		});
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}
}
