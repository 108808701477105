import { Action } from '@ngrx/store';

import { AuthActionTypes } from './auth.action-types';
import { IUser, ILoginParams } from '../../services/auth.types';

export class AuthStoreUser implements Action {
	readonly type = AuthActionTypes.StoreUser;

	constructor(public payload: ILoginParams) {}
}
export class AuthRefreshUser implements Action {
	readonly type = AuthActionTypes.RefreshUser;

	constructor(public payload: { refreshToken: string }) {}
}

export class AuthStoreUserSuccess implements Action {
	readonly type = AuthActionTypes.StoreUserSuccess;

	constructor(public payload: IUser) {}
}
export class AuthStoreUserError implements Action {
	readonly type = AuthActionTypes.StoreUserError;
}

export class AuthResetUser implements Action {
	readonly type = AuthActionTypes.ResetUser;

	constructor(public payload: { refreshToken: string }) {}
}

export class AuthResetUserSuccess implements Action {
	readonly type = AuthActionTypes.ResetUserSuccess;
}

export class AuthResetUserError implements Action {
	readonly type = AuthActionTypes.ResetUserError;
}

export type AuthUnion =
	AuthStoreUser |
	AuthRefreshUser |
	AuthStoreUserSuccess |
	AuthStoreUserError |
	AuthResetUser |
	AuthResetUserSuccess |
	AuthResetUserError;
