export const IDB_OFFLINE_DB  = 'cor-idb__offline';
export const IDB_ONLINE_DB  = 'cor-idb__online';

export const IDB_BROWSE_LIST_META_KEY = 'browseListMeta';
export const IDB_BROWSE_LIST_RECORDS_KEY = 'browseListRecords';
export const IDB_BROWSE_LIST_SECTIONS_KEY = 'browseListSections';
export const IDB_FULL_RECORDS_KEY = 'fullRecords';
export const IDB_RECORDS_ANNOTATIONS_KEY = 'recordsAnnotations';
export const IDB_RECORDS_NOTES_KEY = 'recordsNotes';

const BASE_CONFIG = {
	[IDB_BROWSE_LIST_META_KEY]: 'key',
	[IDB_BROWSE_LIST_RECORDS_KEY]: 'srRecordID, subSectionNameID',
	[IDB_BROWSE_LIST_SECTIONS_KEY]: 'id++',
};

export const IDB_OFFLINE_CONFIG = {
	...BASE_CONFIG,
	[IDB_FULL_RECORDS_KEY]: 'fullRecord.srRecordId, fullRecord.srRecordId',
	[IDB_RECORDS_ANNOTATIONS_KEY]: 'id++, type, value.recordId',
	[IDB_RECORDS_NOTES_KEY]: 'recordNoteId, recordId',
};

export const IDB_ONLINE_CONFIG = {
	...BASE_CONFIG,
};
