import { SafeImage } from './safe-image.pipe';
export { SafeImage } from './safe-image.pipe';

import { KebabCase } from './kebab-case.pipe';
export { KebabCase } from './kebab-case.pipe';

export const Pipes = [
	KebabCase,
	SafeImage
];
