import { Injectable } from '@angular/core';
import { isNil, isEmpty } from 'ramda';
import Dexie from 'dexie';

import { DexieOfflineDBService } from './offline/dexie-offline-db.service';
import { DexieOnlineDBService } from './online/dexie-online-db.service';

@Injectable()
export class DexieTableAbstract {
	protected table: Dexie.Table<any, number>;

	constructor(
		protected db: DexieOfflineDBService | DexieOnlineDBService
	) {}

	public add(item: any): Promise<number> {
		if (isNil(item)) {
			return;
		}

		return this.table.add(item);
	}

	public bulkAdd(items: any[]): Promise<number> {
		if (isNil(items) || items.length === 0) {
			return;
		}

		return this.table.bulkAdd(items);
	}

	public getOne(condition: any): Promise<any> {
		if (isNil(condition) || isEmpty(condition)) {
			return;
		}

		return this.table.get(condition);
	}

	public getAll(): Promise<any[]> {
		return this.table.toArray();
	}

	public getWhere(condition: any): Promise<any[]> {
		if (isNil(condition) || isEmpty(condition)) {
			return;
		}

		return (this.table.where(condition) as any).toArray();
	}

	public update(key: any, changes: any): Promise<number> {
		if (!key || isNil(changes) || isEmpty(changes)) {
			return;
		}

		return this.table.update(key, changes);
	}

	public put(item: any): Promise<number> {
		if (isNil(item) || isEmpty(item)) {
			return;
		}

		return this.table.put(item);
	}

	public clearTable(): Promise<void> {
		return this.table.clear();
	}
}
