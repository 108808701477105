import { Component, EventEmitter, Output, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, ValidationErrors } from '@angular/forms';
import { isNil } from 'ramda';
import { Subject } from 'rxjs';

import { INotesSaved, INotes } from '../../store/notes/records-notes.types';

@Component({
	selector: 'cor-notes-popup',
	templateUrl: './notes-popup.component.html',
	styleUrls: ['./notes-popup.component.scss']
})
export class NotesPopupComponent implements OnInit, OnDestroy {
	@Input() public recordId: string;
	@Input() public notes: INotes;
	@Input() public loading = false;
	@Input() public quickNotes: INotesSaved[] = [];
	@Output() public saved: EventEmitter<Array<string | boolean>> = new EventEmitter<Array<string | boolean>>();
	@Output() public closed: EventEmitter<boolean> = new EventEmitter<boolean>();

	public form: FormGroup;

	private onDestroy$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private formBuilder: FormBuilder,
	) {}

	public ngOnInit(): void {
		this.form = this.formBuilder.group({
			checkboxes: this.buildCheckboxes(),
			textarea: ['', []],
		});
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	public buildCheckboxes(): FormArray {
		const checkBoxArray = this.quickNotes.map(() => this.formBuilder.control(false));

		return this.formBuilder.array(checkBoxArray, {
			validators: this.hasValuesValidator,
		});
	}

	public submit(form: FormGroup): void {
		this.saved.emit([...form.value.checkboxes, form.value.textarea]);
		this.form.reset();
	}

	public close(): void {
		this.closed.emit(true);
	}

	private hasValuesValidator(form: FormGroup): ValidationErrors | null {
		const hasValue = Object.keys(form.controls)
			.filter((control: string) => {
				const value = form.get(control).value;
				return !isNil(value) && value !== '' && value;
			});

		return hasValue.length > 0 ? null : { hasNoValues: true };
	}
}
