import { Action } from '@ngrx/store';

import { RecordsSubnavActionTypes } from './records-sub-navigation.action-types';
import { ISubNavigationRequest, ISubNavigation } from './records-sub-navigation.types';

export class RecordsFetchSubNavigation implements Action {
	readonly type = RecordsSubnavActionTypes.RecordsSubnavFetch;

	constructor(public payload: ISubNavigationRequest) {}
}

export class RecordsFetchSubNavigationSuccess implements Action {
	readonly type = RecordsSubnavActionTypes.RecordsSubnavFetchSuccess;

	constructor(public payload: ISubNavigation) {}
}

export class RecordsFetchSubNavigationError implements Action {
	readonly type = RecordsSubnavActionTypes.RecordsSubnavFetchError;
}

export class RecordsClearSubNavigation implements Action {
	readonly type = RecordsSubnavActionTypes.RecordsSubnavClear;
}

export type RecordsSubNavUnion =
	RecordsFetchSubNavigation |
	RecordsFetchSubNavigationSuccess |
	RecordsFetchSubNavigationError |
	RecordsClearSubNavigation;
