import { Injectable } from '@angular/core';
import Dexie from 'dexie';

import { IDB_ONLINE_DB, IDB_ONLINE_CONFIG } from '../../indexed-db.const';

@Injectable()
export class DexieOnlineDBService extends Dexie {
	constructor() {
		super(IDB_ONLINE_DB);

		this.version(1).stores({
			...IDB_ONLINE_CONFIG,
		});
	}
}
