import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { RecordsBaseService } from "../../shared/services/records-base.service";
import { ISubNavigation } from "../store/sub-navigation/records-sub-navigation.types";

@Injectable()
export class RecordsService extends RecordsBaseService {
	private searchesBaseUrl = "/CGP.WEBAPI/searches";

	constructor(http: HttpClient) {
		super(http);
	}

	public fetchSubNavigation(
		searchOrderId: string
	): Observable<ISubNavigation> {
		return this.http.get<ISubNavigation>(`${this.searchesBaseUrl}/info`, {
			params: { searchOrderId },
		});
	}
}
