import { createFeatureSelector, createSelector } from '@ngrx/store';
import { propOr } from 'ramda';

import { INotes } from './records-notes.types';
import { IRecordsState } from '../records.reducers';

export const selectNotesState = createFeatureSelector<IRecordsState>('records');

export const selectNotes = createSelector(
	selectNotesState,
	(state: IRecordsState): INotes => propOr(null, 'notes', state)
);
