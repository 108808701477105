import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { AuthInterceptor } from './auth.interceptor';
import { UserService } from './user.service';

export const Services = [
	AuthService,
	UserService,
];

export const Guards = [
	AuthGuard,
];

export const HttpInterceptors = [
	{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
