import { Action } from '@ngrx/store';
import { TagsActionTypes } from './records-tags.action-types';
import { ITagRequest } from './records-tags.types';

export class TagsUpdate implements Action {
	readonly type = TagsActionTypes.UpdateTags;

	constructor(public payload: ITagRequest) {}
}

export class TagsUpdateSuccess implements Action {
	readonly type = TagsActionTypes.UpdateTagsSuccess;

	constructor() {}
}

export class TagsUpdateError implements Action {
	readonly type = TagsActionTypes.UpdateTagsError;

	// TODO: TYPE ERRORS
	constructor(public payload: { error: any }) {}
}

export type TagsUnion =
	TagsUpdate |
	TagsUpdateSuccess |
	TagsUpdateError;
