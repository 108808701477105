import { createFeatureSelector, createSelector } from '@ngrx/store';
import { propOr, isNil, drop } from 'ramda';

import { IRecordsState } from '../records.reducers';
import { IRecordDetail, IRecordSection, IFullRecord } from '../../../shared/services/records-base.types';
import { IResponseError } from '../../../shared/shared.types';

export const selectRecordsState = createFeatureSelector<IRecordsState>('records');

export const selectRecord = createSelector(
	selectRecordsState,
	(state: IRecordsState): IRecordDetail => propOr(null, 'detail', state)
);

export const selectRecordError = createSelector(
	selectRecord,
	(record: IRecordDetail): IResponseError => propOr(null, 'error', record)
);

export const selectFullRecord = createSelector(
	selectRecord,
	(record: IRecordDetail): IFullRecord => propOr(null, 'fullRecord', record)
);

export const selectRecordSections = createSelector(
	selectFullRecord,
	(record: IFullRecord): IRecordSection[] => propOr([], 'sections', record)
);

export const selectRecordGeneralSection = createSelector(
	selectRecordSections,
	(sections: IRecordSection[]) => propOr(null, 0, sections)
);

export const selectRecordOtherSections = createSelector(
	selectRecordSections,
	(sections: IRecordSection[]) => {
		const results = drop(1, sections);

		return results.filter((section: IRecordSection) => section.id !== 'secCopy');
	}
);

export const selectCopySection = createSelector(
	selectRecordSections,
	(sections: IRecordSection[]) => {
		const results = drop(1, sections);

		return results.find((section: IRecordSection) => section.id === 'secCopy');
	}
);

export const selectRecordLabeledSections =  createSelector(
	selectRecordSections,
	(sections: IRecordSection[]) => {
		const results = drop(1, sections);

		return results.filter((section: IRecordSection) => !isNil(section.label) && section.label !== '');
	}
);

export const selectRecordUnlabeledSections =  createSelector(
	selectRecordSections,
	(sections: IRecordSection[]) => {
		const results = drop(1, sections);

		return results.filter((section: IRecordSection) => isNil(section.label) || section.label === '');
	}
);
