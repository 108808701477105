import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { NotesActionTypes } from './records-notes.action-types';
import { switchMap, map, catchError, mergeMap, first } from 'rxjs/operators';
import { of } from 'rxjs';

import {
	NotesFetchById,
	NotesStoreOverview,
	NotesStoreOverviewSuccess,
	NotesStoreDetail,
	NotesStoreDetailSuccess
} from './records-notes.actions';
import { NotesService } from '../../services/notes.service';
import { INotes } from './records-notes.types';
import { RecordsFetchDetail } from '../detail/records-detail.actions';

@Injectable()
export class NotesEffects {
	constructor(
		private actions$: Actions,
		private notesService: NotesService,
	) {}

	@Effect() fetchNotesById$ = this.actions$
		.pipe(
			ofType(NotesActionTypes.FetchNotes),
			switchMap((action: NotesFetchById) => this.notesService.fetchById(action.payload)
				.pipe(
					first(),
					map((notes: INotes) => ({ type: NotesActionTypes.FetchNotesSuccess, payload: notes })),
					catchError(() => of({ type: NotesActionTypes.FetchNotesError }))
				)),
		);

	@Effect() storeNotesOverview$ = this.actions$
		.pipe(
			ofType(NotesActionTypes.StoreNotesOverview),
			switchMap((action: NotesStoreOverview) => this.notesService.store(action.payload.searchOrderId, action.payload.noteList)
				.pipe(
					first(),
					mergeMap(() => [
							new NotesStoreOverviewSuccess(),
							new NotesFetchById(action.payload.noteList[0].recordId),
					]),
					catchError(() => of({ type: NotesActionTypes.StoreNotesOverviewError }))
				)
			)
		);

	@Effect() storeNotesDetail$ = this.actions$
		.pipe(
			ofType(NotesActionTypes.StoreNotesDetail),
			switchMap((action: NotesStoreDetail) => this.notesService.store(action.payload.searchOrderId, action.payload.noteList)
				.pipe(
					first(),
					mergeMap(() => [
							new NotesStoreDetailSuccess(),
							new RecordsFetchDetail({
								searchOrderId: action.payload.searchOrderId.toString(),
								recordIds: [action.payload.noteList[0].recordId],
								isOffline: false,
							})
					]),
					catchError(() => of({ type: NotesActionTypes.StoreNotesDetailError }))
				)
			)
		);
}
