import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, first } from 'rxjs/operators';

import { RecordsService } from '../../services/records.service';
import { RecordsSubnavActionTypes } from './records-sub-navigation.action-types';
import { RecordsFetchSubNavigation } from './records-sub-navigation.actions';
import { ISubNavigation } from './records-sub-navigation.types';

@Injectable()
export class RecordsSubNavEffects {
	constructor(
		private actions$: Actions,
		private recordsService: RecordsService,
	) {}

	@Effect() fetchSubNavigation$ = this.actions$
		.pipe(
			ofType(RecordsSubnavActionTypes.RecordsSubnavFetch),
			switchMap((action: RecordsFetchSubNavigation) => this.recordsService.fetchSubNavigation(
				action.payload.searchOrderId
			).pipe(
				first(),
				map((reportTitle: ISubNavigation) => ({ type: RecordsSubnavActionTypes.RecordsSubnavFetchSuccess, payload: { reportTitle } })),
				catchError(() => of({ type: RecordsSubnavActionTypes.RecordsSubnavFetchError }))
			)),
		);
}
