import { IPreviousRoute } from './back-button.types';
import { PREVIOUS_ROUTE_INITIAL_STATE } from './back-button.initial-state';
import { UIUnion } from '../shared.actions';
import { UIActionTypes } from '../shared.action-types';

export function backButtonReducer(
	state: IPreviousRoute = PREVIOUS_ROUTE_INITIAL_STATE,
	action: UIUnion
): IPreviousRoute {
	switch (action.type) {
		case UIActionTypes.StoreRouteSuccess: {
			if (action.payload[1].state.url.indexOf('inbox/') > -1 ) {
				return {
					...state,
					scrollPositionRecords: action.payload[0].payload
				};
			}

			return {
				...state,
				inboxQueryParams: action.payload[1].state.root.queryParams,
				scrollPositionSearches: action.payload[0].payload,
			};
		}

		default: {
			return state;
		}
	}
}
