import { Actions, Effect, ofType } from '@ngrx/effects';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, switchMap, catchError, first, retry } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import { HttpErrorInterceptorUtils } from '../../../shared/services/error.interceptor';
import { IOfflineSearchesState, IOfflineSearch } from '../../../shared/store/offline/offline.types';
import { ISearches } from '../../services/searches.types';
import { SearchesAllActionTypes } from './searches-all.action-types';
import { SearchesFetchAll } from './searches-all.actions';
import { SearchesService } from '../../services/searches.service';
import { selectSearchesOffline } from '../../../shared/store/offline/offline.selectors';

@Injectable()
export class AllSearchesEffects {
	constructor(
		private actions$: Actions,
		private searchesService: SearchesService,
		private store: Store<IOfflineSearchesState>,
		private toastrService: ToastrService,
	) {}

	@Effect() getAllSearches$ = this.actions$
		.pipe(
			ofType(SearchesAllActionTypes.FetchAllSearches),
			switchMap((action: SearchesFetchAll) => this.searchesService.fetch(action.payload.page)
				.pipe(
					retry(1),
					first(),
					map((searches: ISearches) => ({ type: SearchesAllActionTypes.FetchAllSearchesSuccess, payload: searches })),
					catchError(({ error }: HttpErrorResponse) => {
						return this.store.select(selectSearchesOffline)
							.pipe(
								map((offlineSearch: IOfflineSearch) => {
									if (offlineSearch) {
										this.toastrService.info('It seems like you\'re offline. Only your offline search is available.');

										return {
											type: SearchesAllActionTypes.FetchAllSearchesSuccess,
											payload: { records: [offlineSearch.search], paging: null },
										};
									}

									if (error instanceof ProgressEvent) {
										this.toastrService.info('It seems like you\'re offline. Only your offline search is available.');

										return {
											type: SearchesAllActionTypes.FetchAllSearchesError,
											payload: error,
										};
									}

									HttpErrorInterceptorUtils.handleError(error, this.toastrService);

									return {
										type: SearchesAllActionTypes.FetchAllSearchesError,
										payload: error,
									};
								})
							);
					})
				)),
		);
}
