import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ICheckedTag } from './record-card.types';
import { INotesSaved, INotes } from '../../../records/store/notes/records-notes.types';
import { IRecord } from '../../../shared/services/records-base.types';
import { ITagLegend, IUpdateTag } from '../../store/tags/records-tags.types';
import { WindowService } from '../../../shared/services/window.service';

@Component({
	selector: 'cor-record-card',
	templateUrl: './record-card.component.html',
	styleUrls: ['./record-card.component.scss']
})
export class RecordCardComponent {
	@Input() public record: IRecord;
	@Input() public loading: boolean;
	@Input() public notes: INotes;
	@Input() public tagOptions: ITagLegend[];
	@Input() public quickNotes: INotesSaved[] = [];
	@Input() public hasOfflineNotes: boolean;
	@Output() public notesSaved: EventEmitter<Array<string | boolean>> = new EventEmitter<Array<string | boolean>>();
	@Output() public notesOpened: EventEmitter<void> = new EventEmitter<void>();
	@Output() public updateTags: EventEmitter<IUpdateTag> = new EventEmitter<IUpdateTag>();

	public showTagSelect = false;
	public showNotesPopup = false;
	public hasNote = false;
	public checkedTag: ICheckedTag = null;

	constructor(
		private windowService: WindowService,
	) {}

	public onCloseTags(): void {
		this.showTagSelect = false;
	}

	public onSaveNotes(formValue: Array<string | boolean>): void {
		this.notesSaved.emit(formValue);
		this.hasNote = formValue.some(note => !note);
	}

	public onCloseNotes(): void {
		this.windowService.toggleBodyScroll();
		this.showNotesPopup = false;
	}

	public showNotes(): void {
		this.windowService.toggleBodyScroll();
		this.showNotesPopup = !this.showNotesPopup;
		this.notesOpened.emit();
	}

	public onUpdateTags(updateTag: IUpdateTag): void {
		this.checkedTag = {
			tagValue: updateTag.select ? updateTag.tag : null
		};

		this.updateTags.emit(updateTag);
	}
}
