import { IRecord } from '../shared/services/records-base.types';

export enum FieldNames {
	DOMAIN_NAME = 'DOMAIN_NAME',
	SOCIAL_MEDIA_NAME = 'SOCIAL_MEDIA_NAME',
}

export enum FilterValues {
	AL = 'AL',
	TG = 'TG',
	UT = 'UT',
	AC = 'AC',
	PP = 'PP',
	DI = 'DI',
	TT = 'TT',
	IM = 'IM',
	IN = 'IN',
}

export const RECORDS_FILTERS = [{
	label: 'Active Records',
	value: FilterValues.AC,
	filterObject: { isActive: true },
	filterFunction: null,
}, {
	label: 'All Records',
	value: FilterValues.AL,
	filterObject: {},
	filterFunction: null,
}, {
	label: 'Design Only',
	value: FilterValues.IM,
	filterObject: { designIndicator: true },
	filterFunction: null,
}, {
	label: 'Inactive Records',
	value: FilterValues.IN,
	filterObject: { isActive: false },
	filterFunction: null,
}, {
	label: 'Post Publication',
	value: FilterValues.PP,
	filterObject: { isPostPub: true },
	filterFunction: null,
}, {
	label: 'Records w/ Disclaimers',
	value: FilterValues.DI,
	filterObject: { hasDisclaimer: true },
	filterFunction: null,
}, {
	label: 'Records w/ TTAB',
	value: FilterValues.TT,
	filterObject: { ttabInd: true },
	filterFunction: null,
}, {
	label: 'Tagged Records',
	value: FilterValues.TG,
	filterObject: {},
	filterFunction: (record: IRecord) => !!record.tag || !!record.hasNotes,
}, {
	label: 'Untagged Records',
	value: FilterValues.UT,
	filterObject: {},
	filterFunction: (record: IRecord) => !record.tag && !record.hasNotes,
}];
