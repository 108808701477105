import { ActionReducerMap } from '@ngrx/store';
import { recordsAllReducer } from './all/records-all.reducers';
import { recordsSectionsReducer } from './sections/records-sections.reducers';
import { recordsDetailReducer } from './detail/records-detail.reducers';
import { recordsSubNavigationReducer } from './sub-navigation/records-sub-navigation.reducers';
import { IRecordsSectionsState } from './sections/records-sections.types';
import { IAllRecordsState } from './all/records-all.types';
import { IRecordDetail } from '../../shared/services/records-base.types';
import { ISubNavigation } from './sub-navigation/records-sub-navigation.types';
import { recordsNotesReducer } from './notes/records-notes.reducers';
import { INotes } from './notes/records-notes.types';

export interface IRecordsState {
	sections: IRecordsSectionsState;
	all: IAllRecordsState;
	detail: IRecordDetail;
	search: ISubNavigation;
	notes: INotes;
}

export const recordsReducer: ActionReducerMap<any> = {
	sections: recordsSectionsReducer,
	all: recordsAllReducer,
	detail: recordsDetailReducer,
	search: recordsSubNavigationReducer,
	notes: recordsNotesReducer
};
