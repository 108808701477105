import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute, NavigationStart, RouterEvent } from '@angular/router';

import { propOr, pathOr, isEmpty, isNil, equals } from 'ramda';
import { Store } from '@ngrx/store';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil, filter, switchMap, catchError, tap } from 'rxjs/operators';

import { IAllSearchesState } from '../../store/all/searches-all.types';
import { INote } from '../../../records/store/notes/records-notes.types';
import { IPreviousRoute } from '../../../shared/store/back-button/back-button.types';
import { IResponseError } from '../../../shared/shared.types';
import { ISearch, IAnnotation, IOfflineSearch } from '../../../shared/store/offline/offline.types';
import { ISearches } from '../../services/searches.types';
import { ISearchesQueryParams } from '../../searches.types';
import { ITag } from '../../../records/store/tags/records-tags.types';
import { OfflineRecordsService } from '../../../records/services/offline.service';
import { OfflineSearchesService } from '../../services/offline.service';
import { SearchesFetchAll, SearchesClearAll } from '../../store/all/searches-all.actions';
import { SearchesSetOffline, SearchesClearOffline } from '../../../shared/store/offline/offline.actions';
import { selectPreviousRoute } from '../../../shared/store/back-button/back-button.selectors';
import { selectSearchesAll, selectSearchesAllError } from '../../store/all/searches-all.selectors';
import { UIStoreRoute } from '../../../shared/store/shared.actions';
import { WindowService } from '../../../shared/services/window.service';
import { selectSearchesOffline } from '../../../shared/store/offline/offline.selectors';

@Component({
	templateUrl: './overview.page.html',
	styleUrls: ['./overview.page.scss'],
})
export class SearchesOverviewPage implements OnInit, OnDestroy {
	public searches = [];
	public searchesCount: number;
	public searchesFetchedCount = 0;
	public loading = false;
	public error = false;
	public offlineSearch: IOfflineSearch = null;

	private queryParams: ISearchesQueryParams;
	private scrollPosition: number;

	private onDestroy$: Subject<boolean> = new Subject<boolean>();
	private retryingUpload = false;

	constructor(
		private offlineRecordsService: OfflineRecordsService,
		private offlineSearchesService: OfflineSearchesService,
		private route: ActivatedRoute,
		private router: Router,
		private store: Store<IAllSearchesState>,
		private windowService: WindowService,
	) {
		this.router.events
			.pipe(
				takeUntil(this.onDestroy$),
				filter((e: RouterEvent) => e instanceof NavigationStart)
			)
			.subscribe(() => this.store.dispatch(new UIStoreRoute(this.windowService.getScrollPosition())));

		this.store.select(selectPreviousRoute)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((prevRoute: IPreviousRoute) => this.scrollPosition = prevRoute.scrollPositionSearches);

		this.store.select<ISearches>(selectSearchesAll)
			.pipe(
				takeUntil(this.onDestroy$),
				filter(({ error }: IAllSearchesState) => isNil(error))
			)
			.subscribe((searches: ISearches) => {
				this.searches = propOr([], 'records', searches);
				this.searchesFetchedCount = this.searches.length;
				this.searchesCount = pathOr(0, ['paging', 'totalCount'], searches);

				if (this.searches) {
					this.loading = false;
					this.windowService.setScrollPosition(this.scrollPosition);
				}
			});

		this.store.select(selectSearchesAllError)
			.pipe(
				takeUntil(this.onDestroy$),
				filter((error: IResponseError) => !isNil(error))
			).subscribe(() => {
				this.loading = false;
				this.error = true;
			});

		this.store.select(selectSearchesOffline)
			.pipe(
				takeUntil(this.onDestroy$),
			).subscribe((offlineSearch: IOfflineSearch) => {
				if (!equals(this.offlineSearch, offlineSearch)) {
					this.offlineSearch = offlineSearch;
				}

				if(!isNil(offlineSearch) && isNil(offlineSearch.downloadGuid)) {
					setTimeout(() => this.store.dispatch(new SearchesClearOffline()), 100);
				}
			})
	}

	public ngOnInit(): void {
		this.loading = true;

		this.route.queryParams
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((params: ISearchesQueryParams) => {
				this.queryParams = params;

				if (isEmpty(params)) {
					this.router.navigate([], { queryParams: { fetch: 10 } });
					return;
				}

				if (
					((this.searchesFetchedCount === 0 && Number(params.fetch) !== 10) ||
					Number(params.fetch) === this.searchesFetchedCount + 10) &&
					this.searches.length !== Number(params.fetch)
				) {
					this.searchesFetchedCount = Number(params.fetch) - 10;
					this.fetchReports(1);
					return;
				}

				this.loading = false;
			});
	}

	public ngOnDestroy(): void {
		this.store.dispatch(new SearchesClearAll());
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	public fetchReports(start: number): void {
		this.searchesFetchedCount = this.searchesFetchedCount + 10;

		const limit = this.setLimit(start);

		if (this.searchesFetchedCount !== 10) {
			this.router.navigate([], { queryParams: { fetch: this.searchesFetchedCount } });
		}

		this.store.dispatch(new SearchesFetchAll({
			page: {
				start,
				limit,
			},
		}));
	}

	public downloadSearch(search: ISearch): void {
		this.offlineSearchesService.downloadSearch(search.searchOrderId.toString())
			.pipe(
				switchMap(() => this.offlineSearchesService.setDownloadReport(search.searchOrderId.toString())),
				catchError(() => of('DOWNLOAD_FAILED'))
			)
			.subscribe((downloadGuid: string) => {
				if (downloadGuid === 'DOWNLOAD_FAILED') {
					return this.store.dispatch(new SearchesClearOffline({ failed: true }));
				}

				this.store.dispatch(new SearchesSetOffline({
					downloadGuid,
					search,
					searchOrderId: search.searchOrderId,
				}));
			});
	}

	public uploadSearch(search: IOfflineSearch): void {
		this.offlineRecordsService.getAnnotations()
			.then((annotations: IAnnotation[]) => {
				this.updateAnnotations(search, annotations)
					.subscribe((success: boolean) => {
						if (success) {
							this.offlineSearchesService.clearTables();
							this.store.dispatch(new SearchesClearOffline());
						} else {
							this.store.dispatch(new SearchesSetOffline({ ...search }));
						}
					});
			});
	}

	private updateAnnotations(search: IOfflineSearch, annotations: IAnnotation[], forceUpdate: boolean = false): Observable<boolean> {
		return this.offlineSearchesService
			.updateAnnotations({
				searchOrderId: search.searchOrderId,
				downloadGuid: search.downloadGuid,
				tagList: annotations
					.filter((annotation: IAnnotation) => annotation.type === 'tag')
					.map((annotation: IAnnotation) => annotation.value) as ITag[],
				noteList: annotations
					.filter((annotation: IAnnotation) => annotation.type === 'note')
					.map((annotation: IAnnotation) => annotation.value) as INote[],
				forceUpdate,
			})
			.pipe(
				catchError((error: HttpErrorResponse) => this.handleUploadFailure(error, search, annotations))
			);
	}

	private handleUploadFailure({ error }: HttpErrorResponse, search: IOfflineSearch, annotations: IAnnotation[]): Observable<boolean> {
		if (!this.retryingUpload) {
			this.retryingUpload = true;
			const confirmText: string = error.errorType === 'BUSINESS_VALIDATION'
				? error.message || 'Syncing failed. Do you want to overwrite the changes?'
				: 'Sycning failed. Do you want to retry?';
			return this.updateAnnotations(
				search,
				annotations,
				this.windowService.confirmPopup(confirmText),
			);
		} else {
			this.retryingUpload = false;
			return of(false);
		}
	}

	private setLimit(start: number): number {
		if (this.queryParams.fetch) {
			return start === 1 && this.queryParams.fetch !== '10' ? Number(this.queryParams.fetch) : 10;
		}

		return 10;
	}
}
