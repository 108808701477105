import { Action } from '@ngrx/store';

import { RecordsSectionsActionTypes } from './records-sections.action-types';
import { IRecordsSection } from '../../../shared/services/records-base.types';

export class RecordsStoreSections implements Action {
	readonly type = RecordsSectionsActionTypes.RecordsSectionsStore;

	constructor(public payload: IRecordsSection[]) {}
}

export class RecordsClearSections implements Action {
	readonly type = RecordsSectionsActionTypes.RecordsSectionsClear;
}

export type RecordsSectionUnion = RecordsStoreSections | RecordsClearSections;
