import { AccordionComponent } from './accordion/accordion.component';
import { CheckboxComponent } from './form-elements/checkbox/checkbox.component';
import { ButtonComponent } from './button/button.component';
import { LoadingComponent } from './loading/loading.component';
import { LogoComponent } from './logo/logo.component';
import { HeaderComponent } from './header/header.component';
import { SubNavigationComponent } from './sub-navigation/sub-navigation.component';
import { InputTextComponent } from './form-elements/input-text/input-text.component';
import { InputPasswordComponent } from './form-elements/input-password/input-password.component';
import { LinkComponent } from './link/link.component';
import { SwitchComponent } from './switch/switch.component';
import { FilterComponent } from './filter/filter.component';
import { FiltersPopupComponent } from './filters-popup/filters-popup.component';
import { DropdownComponent } from './form-elements/dropdown/dropdown.component';

export const Components = [
	AccordionComponent,
	ButtonComponent,
	CheckboxComponent,
	DropdownComponent,
	LoadingComponent,
	LogoComponent,
	HeaderComponent,
	SubNavigationComponent,
	InputTextComponent,
	InputPasswordComponent,
	LinkComponent,
	SwitchComponent,
	FilterComponent,
	FiltersPopupComponent,
];
