import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'cor-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
	@Input() public options: [];
	@Input() public initialValue: string;
	@Output() public select: EventEmitter<string> = new EventEmitter<string>();


	public selectValue(value: string): void {
		this.select.emit(value);
	}
}
