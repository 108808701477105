import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppWrapperPage } from './shared/pages/app-wrapper/app-wrapper.page';
import { NotFoundPage } from './shared/pages/not-found/not-found.page';
import { LoginPage } from './user/pages/login/login.page';
import { AuthGuard } from './user/services/auth.guard';
import { SearchesOverviewPage } from './searches/pages/overview/overview.page';
import { RecordsOverviewPage } from './records/pages/overview/overview.page';
import { RecordDetailPage } from './records/pages/detail/detail.page';

const routes: Routes = [{
	path: '',
	redirectTo: 'inbox',
	pathMatch: 'full'
}, {
	path: 'login',
	component: LoginPage,
}, {
	path: '',
	component: AppWrapperPage,
	canActivate: [AuthGuard],
	children: [{
		path: 'inbox',
		component: SearchesOverviewPage
	}, {
		path: 'inbox/:search',
		component: RecordsOverviewPage,
	}, {
		path: 'inbox/:search/:record',
		component: RecordDetailPage
	}]
}, {
	path: '**',
	component: NotFoundPage
}];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
