import { DexieOfflineDBService } from './offline/dexie-offline-db.service';
import { DexieOnlineDBService } from './online/dexie-online-db.service';
import { OfflineRecordDetailService } from './offline/full-record/record-detail.service';
import { OfflineRecordsAnnotationsService } from './offline/annotations/records-annotations.service';
import { OfflineRecordsListService } from './offline/browse-list/records-list.service';
import { OfflineRecordsMetaService } from './offline/browse-list/records-meta.service';
import { OfflineRecordsNotesService } from './offline/annotations/records-notes.service';
import { OfflineRecordsSectionsService } from './offline/browse-list/records-sections.service';
import { OnlineRecordsListService } from './online/browse-list/records-list.service';
import { OnlineRecordsMetaService } from './online/browse-list/records-meta.service';
import { OnlineRecordsSectionsService } from './online/browse-list/records-sections.service';

export const OfflineServices = [
	DexieOfflineDBService,
	OfflineRecordDetailService,
	OfflineRecordsAnnotationsService,
	OfflineRecordsListService,
	OfflineRecordsMetaService,
	OfflineRecordsNotesService,
	OfflineRecordsSectionsService,
];

export const OnlineServices = [
	DexieOnlineDBService,
	OnlineRecordsListService,
	OnlineRecordsMetaService,
	OnlineRecordsSectionsService,
];
