import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { isNil } from 'ramda';

import { IUser } from '../services/auth.types';
import { AuthService, TOKEN_STATUS } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(
		private authService: AuthService
	) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.url.indexOf('/cgp.webapi/auth/login') > -1) {
			return next.handle(req);
		}

		return this.authService.getUser()
			.pipe(
				filter((user: IUser | TOKEN_STATUS) => !isNil(user) && user !== TOKEN_STATUS.EXPIRED),
				switchMap((user: IUser) => {
					const authHeader = `bearer ${user.access_token}`;

					const authReq = req.clone({ headers: req.headers.set('Authorization', authHeader) });
					return next.handle(authReq);
				}),
			);
	}
}
