import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
	selector: 'cor-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
	@Input() public icon?: string;
	@Input() public type: string;
	@Input() public disabled = false;
	@Input() public additionalClasses = '';
	@Output() public clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

	public click(): void {
		this.clicked.emit(true);
	}
}
