import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, catchError, withLatestFrom } from 'rxjs/operators';

import { UIActionTypes } from './shared.action-types';
import { IRootState } from '../../app.types';

@Injectable()
export class SharedEffects {
	constructor(
		private actions$: Actions,
		private store: Store<IRootState>,
	) {}

	@Effect() storeRoute$ = this.actions$
		.pipe(
			ofType(UIActionTypes.StoreRoute),
			withLatestFrom(this.store.select('router')),
			map((route) => ({ type: UIActionTypes.StoreRouteSuccess, payload: route })),
			catchError(() => of({ type: UIActionTypes.StoreRouteError }))
		);
}
