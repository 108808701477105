import { createSelector } from '@ngrx/store';
import { propOr } from 'ramda';

import { IUser } from '../../services/auth.types';
import { IUserState } from '../user.types';
import { selectUserState } from '../user.selectors';

export const selectCurrentUser = createSelector(
	selectUserState,
	(state: IUserState): IUser => propOr(null, 'auth', state)
);
