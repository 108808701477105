import { Injectable } from '@angular/core';

import { IRecord, IRecordsSection } from '../../shared/services/records-base.types';
import { OnlineRecordsListService } from '../../indexed-db/services/online/browse-list/records-list.service';
import { OnlineRecordsMetaService } from '../../indexed-db/services/online/browse-list/records-meta.service';
import { OnlineRecordsSectionsService } from '../../indexed-db/services/online/browse-list/records-sections.service';
import { RECORDS_FILTERS, FilterValues } from '../records.const';

@Injectable()
export class OnlineRecordsService {
	constructor(
		private recordsListService: OnlineRecordsListService,
		private recordsMetaService: OnlineRecordsMetaService,
		private recordsSectionsService: OnlineRecordsSectionsService,
	) {}

	public getSections(): Promise<IRecordsSection[]> {
		return this.recordsSectionsService.getAll();
	}

	public getRecords(subSectionNameID: number, filterValue: string = ''): Promise<IRecord[]> {
		const filter: any = this.getFilter(filterValue);

		return this.recordsListService.getWhere({ subSectionNameID, ...filter.filterObject })
			.then((records: IRecord[]) => {
				if (!filter.filterFunction) {
					return records;
				}

				return records.filter(filter.filterFunction);
			});
	}

	public setFilter(filterValue: FilterValues): void {
		this.recordsMetaService.put({ key: 'filter', value: filterValue });
	}

	public getFilters(): any {
		return this.recordsMetaService.getAll();
	}

	private getFilter(filterValue: string): any {
		const filterRecord: any = RECORDS_FILTERS.find((f: any) => f.value === filterValue);

		if (!filterRecord) {
			return {
				filterObject: {},
				filterFunction: null,
			};
		}

		return filterRecord;
	}
}
