import { Component, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { isNil } from 'ramda';
import * as PullToRefresh from 'pulltorefreshjs';

import { IRootState } from '../../../app.types';
import { AuthResetUser } from '../../../user/store/auth/auth.actions';
import { selectCurrentUser } from '../../../user/store/auth/auth.selectors';
import { IUser } from '../../../user/services/auth.types';
import { WindowService } from '../../services/window.service';

@Component({
	selector: 'cor-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
	@Input() public isSyncing = false;

	public loggingOut = false;

	private user: IUser = null;
	private onDestroy$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private store: Store<IRootState>,
		private windowService: WindowService
	) {
		this.store.select<IUser>(selectCurrentUser)
			.pipe(
				takeUntil(this.onDestroy$),
				filter((user: IUser) => !isNil(user))
			)
			.subscribe((user: IUser) => this.user = user);
	}

	public ngAfterViewInit(): void {
		PullToRefresh.init({
			mainElement: '.o-header',
			triggerElement: '.o-header',
		});
	}

	public ngOnDestroy(): void {
		PullToRefresh.destroyAll();

		this.loggingOut = false;

		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	public logout(): void {
		if (this.windowService.confirmPopup('Are you sure you want to log off?')) {
			this.loggingOut = true;
			this.store.dispatch(new AuthResetUser({ refreshToken: this.user.refresh_token }));
		}
	}
}
