import { RecordsDetailUnion } from './records-detail.actions';
import { IRecordDetail } from '../../../shared/services/records-base.types';
import { RECORD_DETAIL_INITIAL_STATE } from './records-detail.initial-state';
import { RecordsDetailActionTypes } from './records-detail.action-types';

export function recordsDetailReducer(
	state: IRecordDetail = RECORD_DETAIL_INITIAL_STATE,
	action: RecordsDetailUnion
): IRecordDetail {
	switch (action.type) {
		case RecordsDetailActionTypes.RecordDetailFetchSuccess: {
			return {
				fullRecord: action.payload[0].fullRecord,
				tag: action.payload[0].tag,
				otherTags:  action.payload[0].otherTags,
				notes: action.payload[0].notes,
				error: null,
			};
		}

		case RecordsDetailActionTypes.RecordDetailFetchError: {
			return {
				...RECORD_DETAIL_INITIAL_STATE,
				error: action.payload,
			};
		}

		case RecordsDetailActionTypes.RecordDetailClear: {
			return RECORD_DETAIL_INITIAL_STATE;
		}

		default: {
			return state;
		}
	}
}
