import { createFeatureSelector, createSelector } from '@ngrx/store';
import { propOr } from 'ramda';

import { IAllSearchesState } from './searches-all.types';
import { IResponseError } from '../../../shared/shared.types';
import { ISearchesState } from '../searches.reducers';

export const selectSearchesState = createFeatureSelector<ISearchesState>('searches');

export const selectSearchesAll = createSelector(
	selectSearchesState,
	(state: ISearchesState): IAllSearchesState => propOr(null, 'all', state)
);

export const selectSearchesAllError = createSelector(
	selectSearchesAll,
	(searches: IAllSearchesState): IResponseError => propOr(null, 'error', searches)
);
