import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISearches, ISearchFetchRequestParams } from '../services/searches.types';

@Injectable()
export class SearchesService {
	constructor(
		private http: HttpClient,
	) {}

	public fetch(page: ISearchFetchRequestParams): Observable<ISearches> {
		return this.http.post<ISearches>('/cgp.webapi/searches', {page});
	}
}
