import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, map, catchError, first } from 'rxjs/operators';
import { of } from 'rxjs';

import { TagsActionTypes } from './records-tags.action-types';
import { TagsUpdate } from './records-tags.actions';
import { TagsService } from '../../services/tags.service';

@Injectable()
export class TagsEffects {
	constructor(
		private actions$: Actions,
		private tagsService: TagsService,
	) {}

	@Effect() updateTags$ = this.actions$
		.pipe(
			ofType(TagsActionTypes.UpdateTags),
			switchMap((action: TagsUpdate) => this.tagsService.update(action.payload.searchOrderId, action.payload.tagList)
				.pipe(
					first(),
					map(() => ({ type: TagsActionTypes.UpdateTagsSuccess })),
					catchError(() => of({ type: TagsActionTypes.UpdateTagsError }))
				)),
		);
}
