import { Component, Input } from '@angular/core';

import { ITag } from '../../../store/tags/records-tags.types';

@Component({
	selector: 'cor-tags-list',
	templateUrl: './tags-list.component.html',
	styleUrls: ['./tags-list.component.scss']
})
export class TagsListComponent {
	@Input() public tag?: ITag;
	@Input() public otherTags?: ITag[];
}
