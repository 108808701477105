import { Injectable } from '@angular/core';

import { DexieOfflineDBService } from '../dexie-offline-db.service';
import { DexieTableAbstract } from '../../dexie-table.abstract';
import { IDB_BROWSE_LIST_RECORDS_KEY } from '../../../indexed-db.const';

@Injectable()
export class OfflineRecordsListService extends DexieTableAbstract {
	constructor(
		db: DexieOfflineDBService
	) {
		super(db);

		this.table = this.db.table(IDB_BROWSE_LIST_RECORDS_KEY);
	}
}
