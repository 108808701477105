import { createFeatureSelector, createSelector } from '@ngrx/store';
import { propOr } from 'ramda';

import { IOfflineSearchesState } from './offline.types';
import { ISharedState } from '../shared.reducers';

export const selectSearchesState = createFeatureSelector<ISharedState>('shared');

export const selectSearchesOffline = createSelector(
	selectSearchesState,
	(state: ISharedState): IOfflineSearchesState => propOr(null, 'offline', state)
);
