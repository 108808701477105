import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { Components } from './components/index';
import { SharedModule } from '../shared/shared.module';
import { Pages } from './pages/index';
import { Services } from './services';
import { recordsReducer } from './store/records.reducers';
import { AllRecordsEffects } from './store/all/records-all.effects';
import { RecordDetailEffects } from './store/detail/records-detail.effects';
import { RecordsSubNavEffects } from './store/sub-navigation/records-sub-navigation.effects';
import { NotesEffects } from './store/notes/records-notes.effects';
import { TagsEffects } from './store/tags/records-tags.effects';
import { IndexedDBModule } from '../indexed-db/indexed-db.module';

@NgModule({
	declarations: [
		...Components,
		...Pages,
	],
	imports: [
		StoreModule.forFeature('records', recordsReducer),
		EffectsModule.forFeature([AllRecordsEffects, RecordDetailEffects, RecordsSubNavEffects, NotesEffects, TagsEffects]),
		CommonModule,
		SharedModule,
		RouterModule,
		IndexedDBModule,
	],
	exports: [
		...Components,
		...Pages,
	],
	providers: [
		...Services
	]
})
export class RecordsModule { }
