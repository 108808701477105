import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, concatMap, first, map, switchMap } from "rxjs/operators";

import { AuthService } from "../../services/auth.service";
import { IUser } from "../../services/auth.types";
import { InfoReset, InfoStore } from "../info/info.actions";
import {
	PreferencesReset,
	PreferencesStore,
} from "../preferences/preferences.actions";
import { AuthActionTypes } from "./auth.action-types";
import {
	AuthRefreshUser,
	AuthResetUser,
	AuthResetUserSuccess,
	AuthStoreUser,
	AuthStoreUserSuccess,
} from "./auth.actions";

@Injectable()
export class AuthEffects {
	constructor(private actions$: Actions, private authService: AuthService) {}

	@Effect() loginUser$ = this.actions$.pipe(
		ofType(AuthActionTypes.StoreUser),
		switchMap((action: AuthStoreUser) =>
			this.authService
				.login(
					action.payload.username,
					action.payload.password,
					action.payload.verificationcode
				)
				.pipe(
					first(),
					concatMap((user: IUser) => [
						new AuthStoreUserSuccess(user),
						new InfoStore(),
						new PreferencesStore(),
					]),
					catchError(() =>
						of({ type: AuthActionTypes.StoreUserError })
					)
				)
		)
	);

	@Effect() refreshUser$ = this.actions$.pipe(
		ofType(AuthActionTypes.RefreshUser),
		switchMap((action: AuthRefreshUser) =>
			this.authService.refresh(action.payload.refreshToken).pipe(
				first(),
				map((user) => ({
					type: AuthActionTypes.StoreUserSuccess,
					payload: user,
				})),
				catchError(() => of({ type: AuthActionTypes.StoreUserError }))
			)
		)
	);

	@Effect() resetUser$ = this.actions$.pipe(
		ofType(AuthActionTypes.ResetUser),
		switchMap((action: AuthResetUser) =>
			this.authService.logout(action.payload.refreshToken).pipe(
				first(),
				concatMap(() => [
					new AuthResetUserSuccess(),
					new InfoReset(),
					new PreferencesReset(),
				]),
				catchError(() => of({ type: AuthActionTypes.ResetUserError }))
			)
		)
	);

	@Effect() logingUserError$ = this.actions$.pipe(
		ofType(AuthActionTypes.StoreUserError),
		concatMap(() => [
			new AuthResetUserSuccess(),
			new InfoReset(),
			new PreferencesReset(),
		])
	);
}
