import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { propOr, isNil } from 'ramda';

const FALLBACK_MESSAGES = {
	default: 'Please verify your connection and try again later.',
	auth: 'Something went wrong while we tried to authenticate you. You might need to login again.',
};

export class HttpErrorInterceptorUtils {
	public static handleError(error: HttpErrorResponse, toastrService: ToastrService): Observable<never> {
		if (!isNil(error) && error.hasOwnProperty('message')) {
			toastrService.error(error.message);
			return throwError(error);
		}

		if (!isNil(error) && error.hasOwnProperty('error')) {
			toastrService.error(propOr(FALLBACK_MESSAGES.auth, 'error_description', error));
			return throwError(error);
		}

		toastrService.error(FALLBACK_MESSAGES.default);
		return throwError(error);
	}
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		private toastrService: ToastrService
	) { }

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const ignore: string[] = [
			'/cgp.webapi/offline/updateAnnotations',
			'/cgp.webapi/searches',
		];

		if (ignore.includes(request.url)) {
			return next.handle(request);
		}

		return next.handle(request)
			.pipe(
				catchError(({ error }: HttpErrorResponse) => HttpErrorInterceptorUtils.handleError(error, this.toastrService))
			);
	}
}
