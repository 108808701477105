import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, first } from 'rxjs/operators';

import { UserService } from '../../services/user.service';
import { PreferencesActionTypes } from './preferences.action-types';

@Injectable()
export class PreferencesEffects {
	constructor(
		private actions$: Actions,
		private userService: UserService
	) {}

	@Effect() userPreferences$ = this.actions$
		.pipe(
			ofType(PreferencesActionTypes.StoreUserPreferences),
			switchMap(() => this.userService.getUserPreferences()
				.pipe(
					first(),
					map((preferences) => ({ type: PreferencesActionTypes.StoreUserPreferencesSuccess, payload: preferences })),
					catchError(() => of({ type: PreferencesActionTypes.StoreUserPreferencesError }))
				))
		);
}
