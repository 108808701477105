import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { WindowService } from './window.service';
import { HttpErrorInterceptor } from './error.interceptor';
import { NotesService } from '../../records/services/notes.service';
import { TagsService } from '../../records/services/tags.service';

export const Services = [
	WindowService,
	NotesService,
	TagsService,
];

export const HttpInterceptors = [
	{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
];
