import { createSelector } from '@ngrx/store';
import { propOr } from 'ramda';

import { IUserPreferences } from '../../services/user.types';
import { IUserState } from '../user.types';
import { selectUserState } from '../user.selectors';

export const selectUserPreferences = createSelector(
	selectUserState,
	(state: IUserState): IUserPreferences => propOr(null, 'preferences', state)
);
