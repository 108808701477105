import { createSelector } from '@ngrx/store';
import { propOr } from 'ramda';

import { IUserInfo } from '../../services/user.types';
import { IUserState } from '../user.types';
import { selectUserState } from '../user.selectors';

export const selectUserInfo = createSelector(
	selectUserState,
	(state: IUserState): IUserInfo => propOr(null, 'info', state)
);
