import { createFeatureSelector, createSelector } from '@ngrx/store';
import { propOr } from 'ramda';

import { IRecordsState } from '../records.reducers';
import { ISubNavigation } from './records-sub-navigation.types';

export const selectRecordsState = createFeatureSelector<IRecordsState>('records');

export const selectSubNavigation = createSelector(
	selectRecordsState,
	(state: IRecordsState): ISubNavigation => propOr(null, 'search', state)
);
