import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'cor-switch',
	templateUrl: './switch.component.html',
	styleUrls: ['./switch.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SwitchComponent),
			multi: true,
		},
	],
})
export class SwitchComponent implements ControlValueAccessor {
	public disabled = false;
	public value = false;

	public onChange: (_) => any = () => { };
	public onTouched = () => { };

	public writeValue(value: boolean): void {
		if (this.value !== value) {
			this.value = value;
			this.onChange(this.value);
		}
	}

	public registerOnChange(onChange: (_) => any): void {
		this.onChange = onChange;
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
