import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { FilterValues } from "../../records/records.const";
import {
	IFetchAllArguments,
	IRecordDetail,
	IRecords,
} from "../../shared/services/records-base.types";

@Injectable()
export class RecordsBaseService {
	protected baseUrl = "/cgp.webapi/searchReview";

	constructor(protected http: HttpClient) {}

	public fetchAll({
		searchOrderId,
		subSectionNameID = null,
		start = 1,
		limit = 10,
		filterBy = [
			{ filterName: "RecordFilter", filterValue: FilterValues.AL },
		],
		isIncludeSections = false,
		isOffline = false,
	}: IFetchAllArguments): Observable<IRecords> {
		return this.http.post<IRecords>(`${this.baseUrl}/browseRecords`, {
			searchOrderId,
			start,
			limit,
			filterBy,
			isIncludeSections,
			subSectionNameID,
			isOffline,
		});
	}

	public fetchByIds(
		searchOrderId: string,
		recordIds: string[],
		isOffline: boolean
	): Observable<IRecordDetail[]> {
		return this.http.post<IRecordDetail[]>(`${this.baseUrl}/fullRecords`, {
			searchOrderId,
			recordIds,
			isOffline,
		});
	}
}
