import { Action } from '@ngrx/store';

import { PreferencesActionTypes } from './preferences.action-types';
import { IUserPreferences } from '../../services/user.types';

export class PreferencesStore implements Action {
	readonly type = PreferencesActionTypes.StoreUserPreferences;
}

export class PreferencesStoreSuccess implements Action {
	readonly type = PreferencesActionTypes.StoreUserPreferencesSuccess;

	constructor(public payload: IUserPreferences) {}
}
export class PreferencesStoreError implements Action {
	readonly type = PreferencesActionTypes.StoreUserPreferencesError;
}

export class PreferencesReset implements Action {
	readonly type = PreferencesActionTypes.ResetUserPreferences;
}


export type PreferencesUnion =
	PreferencesStore |
	PreferencesStoreSuccess |
	PreferencesStoreError |
	PreferencesReset;
