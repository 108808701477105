import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { INotes, INote, INotesSaved } from '../store/notes/records-notes.types';
import { IRecordNote } from '../../shared/services/records-base.types';

@Injectable()
export class NotesService {
	constructor(
		private http: HttpClient,
	) {}

	public static mapNotes(
		formValue: Array<boolean | string>,
		quickNotes: INotesSaved[],
		recordId: string,
		contactInitials: string,
		contactId: string,
	): IRecordNote[] {
		let notes = [];

		// Checkboxes
		quickNotes.map((n: INotesSaved, index: number) => {
			if (formValue[index]) {
				notes = [
					...notes,
					this.mapNote(n.userNotes, recordId, contactInitials, contactId),
				];
			}
		});
		// Textarea
		const note: string = formValue[formValue.length - 1] as string;
		if (note && typeof note === 'string') {
			notes = [
				...notes,
				this.mapNote(note, recordId, contactInitials, contactId),
			];
		}

		return notes;
	}

	private static mapNote(text: string, recordId: string, contactInitials: string, contactId: string): IRecordNote {
		return {
			recordId,
			contactInitials,
			contactId,
			noteText: text,
			isMyNote: true,
		};
	}

	public fetchById(recordId: string): Observable<INotes> {
		return this.http.get<INotes>('/cgp.webapi/notes/recordNotes', {
			params: { recordId }
		});
	}

	public store(searchOrderId: number, noteList: INote[]): Observable<boolean> {
		return this.http.post<boolean>('/cgp.webapi/notes/updateNotes', { searchOrderId, noteList });
	}
}
