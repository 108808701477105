import { isNil } from 'ramda';

import { IUser } from '../services/auth.types';

export default (user: IUser): boolean => {
	if (isNil(user)) {
		return true;
	}

	const expires = new Date(user['.expires']).getTime();
	const now = new Date().getTime();

	return expires <= now;
};
