import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserModule } from './user/user.module';
import { SearchesModule } from './searches/searches.module';
import { RecordsModule } from './records/records.module';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ServiceWorkerModule } from '@angular/service-worker';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
	return localStorageSync({
		keys: ['user', 'ui', { shared: ['offline'] }],
		rehydrate: true,
		storage: localStorage,
	})(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		UserModule,
		SearchesModule,
		RecordsModule,
		StoreModule.forRoot({ router: routerReducer }, { metaReducers }),
		StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
		StoreRouterConnectingModule.forRoot(),
		EffectsModule.forRoot([]),
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
