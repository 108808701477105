import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'safeImage' })
export class SafeImage implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(binary: string): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(binary);
	}
}
