import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { Pages } from './pages';
import { SharedModule } from '../shared/shared.module';
import { IndexedDBModule } from '../indexed-db/indexed-db.module';
import { Components } from './components';
import { Services } from './services';
import { searchesReducer } from './store/searches.reducers';
import { AllSearchesEffects } from './store/all/searches-all.effects';

@NgModule({
	declarations: [
		...Components,
		...Pages,
	],
	imports: [
		StoreModule.forFeature('searches', searchesReducer),
		EffectsModule.forFeature([AllSearchesEffects]),
		SharedModule,
		IndexedDBModule,
		RouterModule,
	],
	providers: [
		...Services,
	],
})
export class SearchesModule {}
