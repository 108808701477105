import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { pathOr } from 'ramda';

@Component({
	selector: 'cor-accordion',
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnChanges {
	@Input() public title?: string;
	@Input() public count?: number;
	@Input() public open?: boolean;
	@Input() public spacing?: boolean;
	@Input() public border = false;
	@Output() public opened: EventEmitter<void> = new EventEmitter<void>();

	public isOpen = false;

	public ngOnChanges({ open }: SimpleChanges): void {
		if (!open) {
			return;
		}

		const isOpen: boolean = pathOr(false, ['currentValue'], open);

		if (this.isOpen !== isOpen) {
			this.isOpen = isOpen;
		}
	}

	public toggle(): void {
		this.isOpen = !this.isOpen;

		if (this.isOpen) {
			this.opened.emit();
		}
	}
}
