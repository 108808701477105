import { propOr } from 'ramda';

import { ISubNavigation } from './records-sub-navigation.types';
import { RECORDS_SUB_NAVIGATION_INITIAL_STATE } from './records-sub-navigation.initial-state';
import { RecordsSubnavActionTypes } from './records-sub-navigation.action-types';
import { RecordsSubNavUnion } from './records-sub-navigation.actions';

export function recordsSubNavigationReducer(
	state: ISubNavigation = RECORDS_SUB_NAVIGATION_INITIAL_STATE,
	action: RecordsSubNavUnion
): ISubNavigation {
	switch (action.type) {
		case RecordsSubnavActionTypes.RecordsSubnavFetchSuccess: {
			return propOr('', 'reportTitle', action.payload);
		}

		case RecordsSubnavActionTypes.RecordsSubnavClear: {
			return RECORDS_SUB_NAVIGATION_INITIAL_STATE;
		}

		default: {
			return state;
		}
	}
}
