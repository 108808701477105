import { isEmpty } from 'ramda';

import { NotesUnion } from './records-notes.actions';
import { INotes } from './records-notes.types';
import { NOTES_INITIAL_STATE } from './records-notes.initial-state';
import { NotesActionTypes } from './records-notes.action-types';

export function recordsNotesReducer(
	state: INotes = NOTES_INITIAL_STATE,
	action: NotesUnion
): INotes {
	switch (action.type) {
		case NotesActionTypes.FetchNotesSuccess: {
			const currentState = state;

			if (action.payload[0]) {
				currentState[action.payload[0].recordId] = action.payload;
			}

			return {
				...currentState,
			};
		}

		default: {
			return state;
		}
	}
}
