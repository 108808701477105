import { Component, OnDestroy } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { isNil } from "ramda";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { TOKEN_STATUS } from "../../services/auth.service";
import { IUser } from "../../services/auth.types";
import { AuthStoreUser } from "../../store/auth/auth.actions";
import { selectCurrentUser } from "../../store/auth/auth.selectors";
import { IUserState } from "../../store/user.types";

@Component({
	selector: "cor-login-page",
	templateUrl: "./login.page.html",
	styleUrls: ["./login.page.scss"],
})
export class LoginPage implements OnDestroy {
	public loginForm: FormGroup;
	public errorMessage: string;
	public onDestroy$: Subject<boolean> = new Subject<boolean>();

	constructor(private router: Router, private store: Store<IUserState>) {
		this.loginForm = new FormGroup({
			username: new FormControl(""),
			password: new FormControl(""),
			verificationcode: new FormControl(""),
		});

		this.store
			.select<IUser>(selectCurrentUser)
			.pipe(
				takeUntil(this.onDestroy$),
				filter(
					(user: IUser | TOKEN_STATUS) =>
						!isNil(user) && user !== TOKEN_STATUS.EXPIRED
				)
			)
			.subscribe(() => this.router.navigate(["/"]));
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	public loginUser(): void {
		var obj = {
			username: encodeURIComponent(this.loginForm.value.username),
			password: encodeURIComponent(this.loginForm.value.password),
			verificationcode: this.loginForm.value.verificationcode,
		};
		this.store.dispatch(new AuthStoreUser(obj));
	}
}
