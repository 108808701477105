import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { isNil } from 'ramda';

import { IUser } from '../services/auth.types';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private authService: AuthService,
	) {}

	public canActivate(): Observable<boolean> {
		return this.authService.getUser()
			.pipe(
				map((user: IUser) => !isNil(user)),
				tap((hasUser: boolean) => {
					if (!hasUser) {
						this.router.navigate(['login']);
					}
				})
			);
	}
}
