import * as Hammer from 'hammerjs';
import { HammerGestureConfig } from '@angular/platform-browser';

export class CustomHammerConfig extends HammerGestureConfig {
	public overrides = {
		swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
		pinch: { enable: false },
		rotate: { enable: false },
		pan: { enable: false }
	} as any;
}
