import { Component, Input, OnChanges } from '@angular/core';

@Component({
	selector: 'cor-link',
	templateUrl: './link.component.html',
	styleUrls: ['./link.component.scss'],
})
export class LinkComponent implements OnChanges {
	@Input() public url?: string;
	public isExternal = false;

	public ngOnChanges(): void {
		const pattern = /^\w*\:\/{2}/;
		this.isExternal = pattern.test(this.url);
	}
}
