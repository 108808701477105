import { Action } from '@ngrx/store';
import { IResponseError } from '../../../shared/shared.types';
import { ISearches } from '../../services/searches.types';
import { ISearchFetchAllParams } from './searches-all.types';
import { SearchesAllActionTypes } from './searches-all.action-types';

export class SearchesFetchAll implements Action {
	readonly type = SearchesAllActionTypes.FetchAllSearches;

	constructor(public payload: ISearchFetchAllParams) {}
}

export class SearchesFetchAllSuccess implements Action {
	readonly type = SearchesAllActionTypes.FetchAllSearchesSuccess;

	constructor(public payload: ISearches) {}
}

export class SearchesFetchAllError implements Action {
	readonly type = SearchesAllActionTypes.FetchAllSearchesError;

	constructor(public payload: IResponseError) {}
}

export class SearchesClearAll implements Action {
	readonly type = SearchesAllActionTypes.ClearAllSearches;
}

export type SearchesAllUnion =
	SearchesFetchAll |
	SearchesFetchAllSuccess |
	SearchesFetchAllError |
	SearchesClearAll;
