import { IUserInfo } from '../../services/user.types';
import { INFO_INITIAL_STATE } from './info.initial-state';
import { InfoActionTypes } from './info.action-types';
import { InfoUnion } from './info.actions';

export function infoReducer(
	state: IUserInfo = INFO_INITIAL_STATE,
	action: InfoUnion
): IUserInfo {
	switch (action.type) {
		case InfoActionTypes.StoreUserInfoSuccess: {
			return action.payload;
		}

		case InfoActionTypes.ResetUserInfo: {
			return INFO_INITIAL_STATE;
		}

		default: {
			return state;
		}
	}
}
