import { Action } from '@ngrx/store';
import { NotesActionTypes } from './records-notes.action-types';
import { INotes, INotesStoreRequest } from './records-notes.types';

export class NotesFetchById implements Action {
	readonly type = NotesActionTypes.FetchNotes;

	constructor(public payload: string) {}
}

export class NotesFetchByIdSuccess implements Action {
	readonly type = NotesActionTypes.FetchNotesSuccess;

	constructor(public payload: INotes) {}
}

export class NotesFetchByIdError implements Action {
	readonly type = NotesActionTypes.FetchNotesError;

	constructor(public payload: { error: any }) {}
}

export class NotesStoreOverview implements Action {
	readonly type = NotesActionTypes.StoreNotesOverview;

	constructor(public payload: INotesStoreRequest) {}
}

export class NotesStoreOverviewSuccess implements Action {
	readonly type = NotesActionTypes.StoreNotesOverviewSuccess;

	constructor() {}
}

export class NotesStoreOverviewError implements Action {
	readonly type = NotesActionTypes.StoreNotesOverviewError;

	constructor(public payload: { error: any }) {}
}

export class NotesStoreDetail implements Action {
	readonly type = NotesActionTypes.StoreNotesDetail;

	constructor(public payload: INotesStoreRequest) {}
}

export class NotesStoreDetailSuccess implements Action {
	readonly type = NotesActionTypes.StoreNotesDetailSuccess;

	constructor() {}
}

export class NotesStoreDetailError implements Action {
	readonly type = NotesActionTypes.StoreNotesDetailError;

	constructor(public payload: { error: any }) {}
}

export type NotesUnion =
	NotesFetchById |
	NotesFetchByIdSuccess |
	NotesFetchByIdError |
	NotesStoreOverview |
	NotesStoreOverviewSuccess |
	NotesStoreOverviewError |
	NotesStoreDetail |
	NotesStoreDetailSuccess |
	NotesStoreDetailError;
