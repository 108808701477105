import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter, OnDestroy } from '@angular/core';
import { pathOr } from 'ramda';
import { FormGroup, FormControl } from '@angular/forms';

import { SEARCH_STATUS } from '../../searches.const';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'cor-search-status',
	templateUrl: './search-status.component.html',
	styleUrls: ['./search-status.component.scss'],
})
export class SearchStatusComponent implements OnChanges, OnDestroy {
	@Input() public disabled?: boolean;
	@Input() public isReportOffline?: boolean;
	@Output() public statusChanged: EventEmitter<string> = new EventEmitter<string>();

	public statusForm: FormGroup;
	public label = 'Offline available';
	public status: SEARCH_STATUS = SEARCH_STATUS.ONLINE;

	private onDestroy$: Subject<boolean> = new Subject<boolean>();
	private previousStatus: SEARCH_STATUS = SEARCH_STATUS.ONLINE;

	constructor() {
		this.statusForm = new FormGroup({
			switch: new FormControl(this.isReportOffline),
		});

		this.statusForm.controls.switch.valueChanges
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((switchValue: boolean) => {
				if (this.status === SEARCH_STATUS.OFFLINE && !switchValue) {
					this.label = 'Uploading';
					this.previousStatus = this.status;
					this.status = SEARCH_STATUS.UPLOADING;
					this.statusChanged.emit(this.status);
				}

				if (this.status === SEARCH_STATUS.ONLINE && switchValue) {
					this.label = 'Downloading';
					this.previousStatus = this.status;
					this.status = SEARCH_STATUS.DOWNLOADING;
					this.statusChanged.emit(this.status);
				}
			});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		const currentlyOffline = pathOr(null, ['isReportOffline', 'currentValue'], changes);
		const noLongerDisabled = this.checkDisabled(
			pathOr(null, ['disabled', 'currentValue'], changes),
			pathOr(null, ['disabled', 'previousValue'], changes),
			this.status
		);

		if (noLongerDisabled) {
			this.status = this.previousStatus;
			this.processStatusChange(this.status);
		}

		if (pathOr(null, ['isReportOffline', 'previousValue'], changes) !== currentlyOffline) {
			this.status = currentlyOffline ? SEARCH_STATUS.OFFLINE : SEARCH_STATUS.ONLINE;
			this.processStatusChange(this.status);
		}

		this.statusForm.controls.switch[this.disabled ? 'disable' : 'enable']();
	}

	private processStatusChange(status: SEARCH_STATUS): void {
		switch (status) {
			case SEARCH_STATUS.ONLINE:
				this.label = 'Offline available';
				this.statusForm.controls.switch.setValue(false);
				break;
			case SEARCH_STATUS.DOWNLOADING:
				this.label = 'Downloading';
				this.statusForm.controls.switch.setValue(false);
				break;
			case SEARCH_STATUS.OFFLINE:
				this.label = 'Offline available';
				this.statusForm.controls.switch.setValue(true);
				break;
			case SEARCH_STATUS.UPLOADING:
				this.label = 'Uploading';
				this.statusForm.controls.switch.setValue(true);
				break;
			default:
				break;
		}
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	private checkDisabled(currentlyDisabled: boolean, previouslyDisabled: boolean, status: SEARCH_STATUS): boolean {
		return	currentlyDisabled !== null && !currentlyDisabled &&
				(previouslyDisabled !== currentlyDisabled) &&
				(status === SEARCH_STATUS.UPLOADING || status === SEARCH_STATUS.DOWNLOADING);
	}
}
