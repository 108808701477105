import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { Pages } from './pages';
import { SharedModule } from '../shared/shared.module';
import { Services, Guards, HttpInterceptors } from './services';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/auth/auth.effects';
import { userReducers } from './store/user.reducers';
import { InfoEffects } from './store/info/info.effects';
import { PreferencesEffects } from './store/preferences/preferences.effects';

@NgModule({
	imports: [
		StoreModule.forFeature('user', userReducers),
		EffectsModule.forFeature([AuthEffects, InfoEffects, PreferencesEffects]),
		SharedModule,
	],
	declarations: [
		...Pages,
	],
	providers: [
		...Services,
		...Guards,
		...HttpInterceptors,
	],
	exports: [
		...Pages,
	]
})
export class UserModule {}
