import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';

import { Components } from './components';
import { Services, HttpInterceptors } from './services';
import { Pipes } from './pipes';
import { Pages } from './pages';
import { Directives } from './directives';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { sharedReducer } from './store/shared.reducers';
import { SharedEffects } from './store/shared.effects';
import { CustomHammerConfig } from './config/custom-hammer.config';

@NgModule({
	declarations: [
		...Components,
		...Pages,
		...Pipes,
		...Directives,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		RouterModule,
		StoreModule.forFeature('shared', sharedReducer),
		EffectsModule.forFeature([SharedEffects]),
		ToastrModule.forRoot({
			closeButton: true,
			progressBar: true,
			progressAnimation: 'decreasing',
			positionClass: 'toast-bottom-right',
			tapToDismiss: true,
			preventDuplicates: true,
			countDuplicates: true,
			resetTimeoutOnDuplicate: true,
		}),
	],
	exports: [
		...Components,
		...Pages,
		...Pipes,
		...Directives,
		BrowserAnimationsModule,
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		RouterModule,
		ToastrModule,
	],
	providers: [
		...Services,
		...HttpInterceptors,
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: CustomHammerConfig,
		}
	]
})
export class SharedModule { }
