export enum NotesActionTypes {
	FetchNotes = '[Notes] Fetch Notes',
	FetchNotesSuccess = '[Notes] Fetch Notes Success',
	FetchNotesError = '[Notes] Fetch Notes Error',
	StoreNotesOverview = '[Notes] Store Notes Overview',
	StoreNotesOverviewSuccess = '[Notes] Store Notes Overview Success',
	StoreNotesOverviewError = '[Notes] Store Notes Overview Error',
	StoreNotesDetail = '[Notes] Store Detail Notes',
	StoreNotesDetailSuccess = '[Notes] Store Notes Detail Success',
	StoreNotesDetailError = '[Notes] Store Notes Detail Error',
}
