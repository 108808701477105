import { NotesService } from './notes.service';
import { OfflineRecordsService } from './offline.service';
import { OnlineRecordsService } from './online.service';
import { RecordsService } from './records.service';
import { TagsService } from './tags.service';

export const Services = [
	NotesService,
	OfflineRecordsService,
	OnlineRecordsService,
	RecordsService,
	TagsService,
];
