import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, first } from 'rxjs/operators';

import { UserService } from '../../services/user.service';
import { InfoActionTypes } from './info.action-types';

@Injectable()
export class InfoEffects {
	constructor(
		private actions$: Actions,
		private userService: UserService
	) {}

	@Effect() userInfo$ = this.actions$
		.pipe(
			ofType(InfoActionTypes.StoreUserInfo),
			switchMap(() => this.userService.getUserInfo()
				.pipe(
					first(),
					map((info) => ({ type: InfoActionTypes.StoreUserInfoSuccess, payload: info })),
					catchError(() => of({ type: InfoActionTypes.StoreUserInfoError }))
				))
		);
}
