import { NgModule } from '@angular/core';

import { OfflineServices, OnlineServices } from './services';

@NgModule({
	providers: [
		...OfflineServices,
		...OnlineServices,
	],
})
export class IndexedDBModule {}
