import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, first } from 'rxjs/operators';

import { RecordsService } from '../../services/records.service';
import { RecordsDetailActionTypes } from './records-detail.action-types';
import { RecordsFetchDetail } from './records-detail.actions';
import { IRecordDetail } from '../../../shared/services/records-base.types';

@Injectable()
export class RecordDetailEffects {
	constructor(
		private actions$: Actions,
		private recordsService: RecordsService,
	) {}

	@Effect() fetchById$ = this.actions$
		.pipe(
			ofType(RecordsDetailActionTypes.RecordDetailFetch),
			switchMap((action: RecordsFetchDetail) => this.recordsService.fetchByIds(
				action.payload.searchOrderId, action.payload.recordIds, action.payload.isOffline
			).pipe(
				first(),
				map((records: IRecordDetail[]) => ({ type: RecordsDetailActionTypes.RecordDetailFetchSuccess, payload: records })),
				catchError((error: any) => of({ type: RecordsDetailActionTypes.RecordDetailFetchError, payload: error }))
			)),
		);
}
