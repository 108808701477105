import { Injectable } from '@angular/core';
import Dexie from 'dexie';

import { IDB_OFFLINE_DB, IDB_OFFLINE_CONFIG } from '../../indexed-db.const';

@Injectable()
export class DexieOfflineDBService extends Dexie {
	constructor() {
		super(IDB_OFFLINE_DB);

		this.version(1).stores({
			...IDB_OFFLINE_CONFIG,
		});
	}
}
