import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { isNil } from 'ramda';

import { IOfflineSearch } from '../../../shared/store/offline/offline.types';
import { ISearch } from '../../../shared/store/offline/offline.types';
import { SEARCH_STATUS } from '../../searches.const';

@Component({
	selector: 'cor-search-card',
	templateUrl: './search-card.component.html',
	styleUrls: ['./search-card.component.scss'],
})
export class SearchCardComponent implements OnChanges, OnDestroy {
	@Input() search?: ISearch;
	@Input() offlineSearch?: IOfflineSearch;

	@Output() downloadSearch: EventEmitter<ISearch> = new EventEmitter<ISearch>();
	@Output() uploadSearch: EventEmitter<IOfflineSearch> = new EventEmitter<IOfflineSearch>();

	public isDisabled = false;
	public isDownloading = false;
	public isReportOffline: boolean;
	public isUploading = false;
	public searchStatus = SEARCH_STATUS;

	private onDestroy$: Subject<boolean> = new Subject<boolean>();

	public ngOnChanges(): void {
		this.isDownloading = false;
		this.isUploading = false;

		this.setDisabled();
		this.setOffline();
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	public handleStatusChange(status: string): void {
		if (status === this.searchStatus.DOWNLOADING) {
			this.isDisabled = true;
			this.isDownloading = true;

			this.downloadSearch.emit(this.search);
		}

		if (status === this.searchStatus.UPLOADING) {
			this.isDisabled = true;
			this.isUploading = true;

			this.uploadSearch.emit(this.offlineSearch);
		}
	}

	private setDisabled(): void {
		this.isDisabled =
			!isNil(this.search) &&
			!isNil(this.offlineSearch) &&
			this.offlineSearch.downloadGuid !== null &&
			this.offlineSearch.searchOrderId !== this.search.searchOrderId;
	}

	private setOffline(): void {
		this.isReportOffline =
			!isNil(this.search) &&
			!isNil(this.offlineSearch) &&
			this.offlineSearch.downloadGuid !== null &&
			this.offlineSearch.searchOrderId === this.search.searchOrderId;
	}
}
