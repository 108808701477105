import { SearchesAllActionTypes } from './searches-all.action-types';
import { SearchesAllUnion } from './searches-all.actions';
import { IAllSearchesState } from './searches-all.types';
import { SEARCHES_ALL_INITIAL_STATE } from './searches-all.initial-state';

export function searchesAllReducer(
	state: IAllSearchesState = SEARCHES_ALL_INITIAL_STATE,
	action: SearchesAllUnion
): IAllSearchesState {
	switch (action.type) {
		case SearchesAllActionTypes.FetchAllSearchesSuccess: {
			return {
				...state,
				records: state.records ? state.records.concat(action.payload.records) : action.payload.records,
				paging: action.payload.paging,
				error: null,
			};
		}

		case SearchesAllActionTypes.FetchAllSearchesError: {
			return {
				...SEARCHES_ALL_INITIAL_STATE,
				error: action.payload,
			};
		}

		case SearchesAllActionTypes.ClearAllSearches: {
			return SEARCHES_ALL_INITIAL_STATE;
		}

		default: {
			return state;
		}
	}
}
