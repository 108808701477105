import { Component, Input } from '@angular/core';
import { IFilter } from '../../shared.types';

@Component({
	selector: 'cor-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
	@Input() public hideFilter: boolean;
	@Input() public filters: IFilter[] = [];
}
