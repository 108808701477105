import { RecordsSectionUnion } from './records-sections.actions';
import { RecordsSectionsActionTypes } from './records-sections.action-types';
import { IRecordsSectionsState } from './records-sections.types';
import { RECORDS_SECTIONS_INITIAL_STATE } from './records-sections.initial-state';

export function recordsSectionsReducer(
	state: IRecordsSectionsState = RECORDS_SECTIONS_INITIAL_STATE,
	action: RecordsSectionUnion
): IRecordsSectionsState {
	switch (action.type) {
		case RecordsSectionsActionTypes.RecordsSectionsStore: {
			return action.payload;
		}

		case RecordsSectionsActionTypes.RecordsSectionsClear: {
			return RECORDS_SECTIONS_INITIAL_STATE;
		}

		default: {
			return state;
		}
	}
}
