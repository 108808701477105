import { NotesPopupComponent } from './notes-popup/notes-popup.component';
import { RecordCardComponent } from './record-card/record-card.component';
import { TagsListComponent } from './tags/tags-list/tags-list.component';
import { TagsSelectComponent } from './tags/tags-select/tags-select.component';

export const Components = [
	NotesPopupComponent,
	RecordCardComponent,
	TagsListComponent,
	TagsSelectComponent,
];
