import { ActionReducerMap } from '@ngrx/store';
import { IAllSearchesState } from './all/searches-all.types';
import { searchesAllReducer } from './all/searches-all.reducers';

export interface ISearchesState {
	all: IAllSearchesState;
}

export const searchesReducer: ActionReducerMap<any> = {
	all: searchesAllReducer,
};
