import { Action } from '@ngrx/store';
import { UIActionTypes } from './shared.action-types';
import { IPreviousRoute } from './back-button/back-button.types';

export class UIStoreRoute implements Action {
	readonly type = UIActionTypes.StoreRoute;

	constructor(public payload: number) {}
}

export class UIStoreRouteSuccess implements Action {
	readonly type = UIActionTypes.StoreRouteSuccess;

	constructor(public payload: IPreviousRoute) {}
}
export class UIStoreRouteError implements Action {
	readonly type = UIActionTypes.StoreRouteError;

	constructor(public payload: { error: any }) {}
}

export type UIUnion =
	UIStoreRoute |
	UIStoreRouteSuccess |
	UIStoreRouteError;
