import { Injectable } from '@angular/core';

import { DexieOnlineDBService } from '../dexie-online-db.service';
import { DexieTableAbstract } from '../../dexie-table.abstract';
import { IDB_BROWSE_LIST_META_KEY } from '../../../indexed-db.const';

@Injectable()
export class OnlineRecordsMetaService extends DexieTableAbstract {
	constructor(
		db: DexieOnlineDBService
	) {
		super(db);

		this.table = this.db.table(IDB_BROWSE_LIST_META_KEY);
	}
}
