import { IUserState } from './user.types';
import { authReducer } from './auth/auth.reducers';
import { ActionReducerMap } from '@ngrx/store';
import { infoReducer } from './info/info.reducers';
import { preferencesReducer } from './preferences/preferences.reducers';
import { AUTH_INITIAL_STATE } from './auth/auth.initial-state';
import { INFO_INITIAL_STATE } from './info/info.initial-state';
import { PREFERENCES_INITIAL_STATE } from './preferences/preferences.initial-state';

export const USER_INITAL_STATE: IUserState = {
	auth: AUTH_INITIAL_STATE,
	info: INFO_INITIAL_STATE,
	preferences: PREFERENCES_INITIAL_STATE,
};

export const userReducers: ActionReducerMap<IUserState> = {
	auth: authReducer,
	info: infoReducer,
	preferences: preferencesReducer
};
