import { IAllRecordsState } from './records-all.types';
import { RECORDS_ALL_INITIAL_STATE } from './records-all.initial-state';
import { RecordsAllUnion } from './records-all.actions';
import { RecordsAllActionTypes } from './records-all.action-types';

export function recordsAllReducer(
	state: IAllRecordsState = RECORDS_ALL_INITIAL_STATE,
	action: RecordsAllUnion
) {
	switch (action.type) {
		case RecordsAllActionTypes.RecordsAllFetchSuccess: {
			return {
				...state,
				records: action.payload.records,
				paging: null,
				error: null,
			};
		}

		case RecordsAllActionTypes.RecordsAllFetchError: {
			return {
				...RECORDS_ALL_INITIAL_STATE,
				error: action.payload,
			};
		}

		case RecordsAllActionTypes.RecordsAllClear: {
			return RECORDS_ALL_INITIAL_STATE;
		}

		default: {
			return state;
		}
	}
}
