import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isNil, splitEvery } from "ramda";
import { Observable, combineLatest, from } from "rxjs";
import { first, switchMap, tap } from "rxjs/operators";
import { RecordsBaseService } from "src/app/shared/services/records-base.service";

import { OfflineRecordsAnnotationsService } from "../../indexed-db/services/offline/annotations/records-annotations.service";
import { OfflineRecordsNotesService } from "../../indexed-db/services/offline/annotations/records-notes.service";
import { OfflineRecordsListService } from "../../indexed-db/services/offline/browse-list/records-list.service";
import { OfflineRecordsMetaService } from "../../indexed-db/services/offline/browse-list/records-meta.service";
import { OfflineRecordsSectionsService } from "../../indexed-db/services/offline/browse-list/records-sections.service";
import { OfflineRecordDetailService } from "../../indexed-db/services/offline/full-record/record-detail.service";
import {
	IRecord,
	IRecordDetail,
	IRecordNote,
	IRecords,
} from "../../shared/services/records-base.types";
import { IAnnotations } from "./offline.types";

@Injectable()
export class OfflineSearchesService extends RecordsBaseService {
	private offlineBaseUrl = "/cgp.webapi/offline";
	private notesBaseUrl = "/cgp.webapi/notes";

	constructor(
		protected http: HttpClient,
		private recordDetailService: OfflineRecordDetailService,
		private recordsAnnotationsService: OfflineRecordsAnnotationsService,
		private recordsListService: OfflineRecordsListService,
		private recordsMetaService: OfflineRecordsMetaService,
		private recordsNotesService: OfflineRecordsNotesService,
		private recordsSectionsService: OfflineRecordsSectionsService
	) {
		super(http);
	}

	public downloadSearch(
		searchOrderId: string
	): Observable<(IRecords | IRecordDetail[][])[]> {
		return this.clearTables().pipe(
			switchMap(() =>
				combineLatest(
					this.getBrowselist(searchOrderId),
					this.getAllNotes(searchOrderId)
				)
			)
		);
	}

	public setDownloadReport(searchOrderId: string): Observable<string> {
		return this.http.post<string>(
			`${this.offlineBaseUrl}/setDownloadReport?searchOrderId=${searchOrderId}`,
			null
		);
	}

	public updateAnnotations(annotations: IAnnotations): Observable<boolean> {
		return this.http.post<boolean>(
			`${this.offlineBaseUrl}/updateAnnotations`,
			annotations
		);
	}

	public clearTables(): Observable<void[]> {
		return from(
			Promise.all([
				this.recordDetailService.clearTable(),
				this.recordsAnnotationsService.clearTable(),
				this.recordsListService.clearTable(),
				this.recordsMetaService.clearTable(),
				this.recordsNotesService.clearTable(),
				this.recordsSectionsService.clearTable(),
			])
		);
	}

	private getAllNotes(searchOrderId: string): Observable<any> {
		return this.http
			.get<IRecordNote[]>(`${this.notesBaseUrl}/allNotes`, {
				params: { searchOrderId },
			})
			.pipe(
				first(),
				tap((notes: IRecordNote[]) =>
					this.recordsNotesService.bulkAdd(notes)
				)
			);
	}

	private getBrowselist(
		searchOrderId: string
	): Observable<IRecords | IRecordDetail[][]> {
		return this.fetchAll({
			searchOrderId,
			limit: 1,
			isIncludeSections: true,
			isOffline: true,
		}).pipe(
			first(),
			tap((records: IRecords) => {
				this.recordsMetaService.add({
					key: "searchOrderId",
					value: searchOrderId,
				});
				this.recordsSectionsService.bulkAdd(records.sections);
				this.recordsListService.bulkAdd(records.records);
			}),
			switchMap((records: IRecords) =>
				combineLatest(
					this.getFullRecords(searchOrderId, records.records)
				)
			)
		);
	}

	private getFullRecords(
		searchOrderId: string,
		records: IRecord[]
	): Observable<IRecordDetail[]>[] {
		if (isNil(records) || records.length === 0) {
			return [];
		}

		const recordIds: string[][] = splitEvery(
			25,
			records.map((record: IRecord) => record.srRecordID)
		);

		return recordIds.map((ids: string[]) => {
			return this.fetchByIds(searchOrderId, ids, true).pipe(
				first(),
				tap((details: IRecordDetail[]) =>
					this.recordDetailService.bulkAdd(details)
				)
			);
		});
	}
}
