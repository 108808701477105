import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, catchError, concatMap, first, tap, map } from 'rxjs/operators';

import { IRecords } from '../../../shared/services/records-base.types';
import { OnlineRecordsListService } from '../../../indexed-db/services/online/browse-list/records-list.service';
import { OnlineRecordsMetaService } from '../../../indexed-db/services/online/browse-list/records-meta.service';
import { OnlineRecordsSectionsService } from '../../../indexed-db/services/online/browse-list/records-sections.service';
import { RecordsAllActionTypes } from './records-all.action-types';
import { RecordsFetchAll, RecordsFetchAllSuccess } from './records-all.actions';
import { RecordsService } from '../../services/records.service';
import { RecordsStoreSections } from '../sections/records-sections.actions';

@Injectable()
export class AllRecordsEffects {
	constructor(
		private actions$: Actions,
		private recordsListService: OnlineRecordsListService,
		private recordsMetaService: OnlineRecordsMetaService,
		private recordsSectionsService: OnlineRecordsSectionsService,
		private recordsService: RecordsService,
	) {}

	@Effect() fetchAll$ = this.actions$
		.pipe(
			ofType(RecordsAllActionTypes.RecordsAllFetch),
			switchMap((action: RecordsFetchAll) => this.recordsService
				.fetchAll({
					filterBy: action.payload.filterBy,
					isIncludeSections: action.payload.isIncludeSections,
					isOffline: action.payload.isOffline,
					limit: action.payload.limit,
					searchOrderId: action.payload.searchOrderId,
					start: action.payload.start,
					subSectionNameID: action.payload.subSectionNameID,
				})
				.pipe(
					first(),
					tap(async (records: IRecords) => {
						const clear = [
							this.recordsMetaService.clearTable(),
							this.recordsSectionsService.clearTable(),
							this.recordsListService.clearTable(),
						];
						const insert = [
							this.recordsMetaService.add({ key: 'searchOrderId', value: action.payload.searchOrderId }),
							this.recordsSectionsService.bulkAdd(records.sections),
							this.recordsListService.bulkAdd(records.records),
						];

						return Promise.all(clear).then(() => Promise.all(insert));
					}),
					concatMap((records: IRecords) => {
						return [
							new RecordsFetchAllSuccess(records),
							new RecordsStoreSections(records.sections)
						];
					}),
					catchError((error: any) => of({ type: RecordsAllActionTypes.RecordsAllFetchError, payload: error }))
				)
			)
		);
}
