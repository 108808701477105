import { createFeatureSelector, createSelector } from '@ngrx/store';
import { propOr } from 'ramda';

import { IRecordsState } from '../records.reducers';
import { IAllRecordsState } from './records-all.types';
import { IResponseError } from '../../../shared/shared.types';

export const selectRecordsState = createFeatureSelector<IRecordsState>('records');

export const selectRecordsAll = createSelector(
	selectRecordsState,
	(state: IRecordsState): IAllRecordsState => propOr(null, 'all', state)
);

export const selectRecordsError = createSelector(
	selectRecordsAll,
	(state: IAllRecordsState): IResponseError => propOr(null, 'error', state)
);
