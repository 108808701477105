import { Action } from '@ngrx/store';

import { InfoActionTypes } from './info.action-types';
import { IUserInfo } from '../../services/user.types';

export class InfoStore implements Action {
	readonly type = InfoActionTypes.StoreUserInfo;
}

export class InfoStoreSuccess implements Action {
	readonly type = InfoActionTypes.StoreUserInfoSuccess;

	constructor(public payload: IUserInfo) {}
}
export class InfoStoreError implements Action {
	readonly type = InfoActionTypes.StoreUserInfoError;
}

export class InfoReset implements Action {
	readonly type = InfoActionTypes.ResetUserInfo;
}

export type InfoUnion =
	InfoStore |
	InfoStoreSuccess |
	InfoStoreError |
	InfoReset;
