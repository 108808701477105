import { IUser } from '../../services/auth.types';
import { AUTH_INITIAL_STATE } from './auth.initial-state';
import { AuthActionTypes } from './auth.action-types';
import { AuthUnion } from './auth.actions';

export function authReducer(
	state: IUser = AUTH_INITIAL_STATE,
	action: AuthUnion
): IUser {
	switch (action.type) {
		case AuthActionTypes.StoreUserSuccess: {
			return action.payload;
		}

		case AuthActionTypes.ResetUserSuccess: {
			return AUTH_INITIAL_STATE;
		}

		default: {
			return state;
		}
	}
}
