import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ITag } from '../store/tags/records-tags.types';

@Injectable()
export class TagsService {
	constructor(
		private http: HttpClient,
	) {}

	public update(searchOrderId: number, tagList: ITag[]): Observable<boolean> {
		return this.http.post<boolean>('/cgp.webapi/tags/updateTags', { searchOrderId, tagList });
	}
}
