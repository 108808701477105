import { propOr, isNil } from 'ramda';

import { IUser } from '../services/auth.types';

export default (user: IUser): boolean => {
	if (isNil(user)) {
		return false;
	}

	const accessToken = propOr(null, 'access_token', user);

	return !isNil(accessToken);
};
