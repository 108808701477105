import { createFeatureSelector, createSelector } from '@ngrx/store';
import { propOr } from 'ramda';
import { IPreviousRoute } from './back-button.types';

export const selectPreviousRouteState = createFeatureSelector('shared');

export const selectPreviousRoute = createSelector(
	selectPreviousRouteState,
	(state: IPreviousRoute) => propOr('inbox', 'backButton', state)
);
