import { Component, EventEmitter, Output, Input } from '@angular/core';
import { IFilter } from '../../shared.types';

@Component({
	selector: 'cor-filters-popup',
	templateUrl: './filters-popup.component.html',
	styleUrls: ['./filters-popup.component.scss']
})
export class FiltersPopupComponent {
	@Input() public filters: IFilter[] = [];
	@Input() public filterValue: string;
	@Output() public select: EventEmitter<string> = new EventEmitter<string>();
	@Output() public close: EventEmitter<void> = new EventEmitter<void>();

	public selectValue(value: string): void {
		this.select.emit(value);
	}

	public closePopup() {
		this.close.emit();
	}
}
