import { ActionReducerMap } from '@ngrx/store';
import { backButtonReducer } from './back-button/back-button.reducers';
import { searchesOfflineReducer } from './offline/offline.reducers';
import { IPreviousRoute } from './back-button/back-button.types';
import { IOfflineSearchesState } from './offline/offline.types';

export interface ISharedState {
	backButton: IPreviousRoute;
	offline: IOfflineSearchesState;
}

export const sharedReducer: ActionReducerMap<any> = {
	backButton: backButtonReducer,
	offline: searchesOfflineReducer
};
