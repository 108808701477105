import { pathOr } from 'ramda';

import { SearchesOfflineTypes } from './offline.action-types';
import { SearchesOfflineUnion } from './offline.actions';
import { IOfflineSearchesState } from './offline.types';
import { SEARCHES_OFFLINE_INITIAL_STATE, SEARCHES_OFFLINE_FAILED_STATE } from './offline.initial-state';

export function searchesOfflineReducer(
	state: IOfflineSearchesState = SEARCHES_OFFLINE_INITIAL_STATE,
	action: SearchesOfflineUnion
): IOfflineSearchesState {
	switch (action.type) {
		case SearchesOfflineTypes.SetOfflineSearch: {
			return action.payload;
		}

		case SearchesOfflineTypes.ClearOfflineSearch: {
			const failed = pathOr(false, ['payload', 'failed'], action);

			return failed ? SEARCHES_OFFLINE_FAILED_STATE : SEARCHES_OFFLINE_INITIAL_STATE;
		}

		default: {
			return state;
		}
	}
}
