import { Action } from '@ngrx/store';

import { RecordsDetailActionTypes } from './records-detail.action-types';
import { IRecordsFetchDetailParams } from './records-detail.types';
import { IRecordDetail } from '../../../shared/services/records-base.types';
import { IResponseError } from '../../../shared/shared.types';

export class RecordsFetchDetail implements Action {
	readonly type = RecordsDetailActionTypes.RecordDetailFetch;

	constructor(public payload: IRecordsFetchDetailParams) {}
}

export class RecordsFetchDetailSuccess implements Action {
	readonly type = RecordsDetailActionTypes.RecordDetailFetchSuccess;

	constructor(public payload: IRecordDetail) {}
}

export class RecordsFetchDetailError implements Action {
	readonly type = RecordsDetailActionTypes.RecordDetailFetchError;

	constructor(public payload: IResponseError) {}
}

export class RecordsClearDetail implements Action {
	readonly type = RecordsDetailActionTypes.RecordDetailClear;
}

export type RecordsDetailUnion = RecordsFetchDetail | RecordsFetchDetailSuccess | RecordsFetchDetailError | RecordsClearDetail;
