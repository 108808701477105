import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IUserInfo, IUserPreferences } from "../services/user.types";

export enum TOKEN_STATUS {
	EXPIRED = "EXPIRED",
}

@Injectable()
export class UserService {
	private userBaseUrl = "/cgp.webapi/user";

	constructor(private http: HttpClient) {}

	public getUserInfo(): Observable<IUserInfo> {
		return this.http.get<IUserInfo>(`${this.userBaseUrl}/tokenUser`);
	}

	public getUserPreferences(): Observable<IUserPreferences> {
		return this.http.get<IUserPreferences>(
			`${this.userBaseUrl}/userPreferences`
		);
	}
}
