import { Component, Input } from '@angular/core';

import { LOADING_ICON_SIZE } from './loading.const';

@Component({
	templateUrl: 'loading.component.html',
	styleUrls: ['./loading.component.scss'],
	selector: 'cor-loading'
})
export class LoadingComponent {
	@Input() public loading = false;
	@Input() public error = false;
	@Input() public color = '#fff';
	@Input() public size = LOADING_ICON_SIZE.default;
}
