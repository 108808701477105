import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'cor-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckboxComponent),
			multi: true,
		},
	],
})
export class CheckboxComponent implements ControlValueAccessor {
	@Input() label?: string;
	@Input() disabled = false;

	public value: boolean;

	public onChange: (_) => any = () => { };

	public onTouched: (_) => any = () => { };

	public writeValue(value: boolean): void {
		this.value = value;
		this.onChange(this.value);
	}

	public registerOnChange(onChange: (_) => any): void {
		this.onChange = onChange;
	}

	public registerOnTouched(onTouch: () => void): void {
		this.onTouched = onTouch;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
