import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { isNil, pathOr, propOr, drop } from 'ramda';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { FieldNames } from '../../records.const';
import { FilterValues } from '../../records.const';
import { INotesSaved, INotes } from '../../../records/store/notes/records-notes.types';
import { IOfflineSearch } from '../../../shared/store/offline/offline.types';
import { IRecordDetail, IRecordSection, IFullRecord, IRecord, IRecordFilter } from '../../../shared/services/records-base.types';
import { IRecordsState } from '../../store/records.reducers';
import { IResponseError } from '../../../shared/shared.types';
import { ISubNavigation } from '../../store/sub-navigation/records-sub-navigation.types';
import { ITag, ITagLegend, IUpdateTag } from '../../store/tags/records-tags.types';
import { IUserPreferences, IUserInfo } from '../../../user/services/user.types';
import { NotesService } from '../../services/notes.service';
import { NotesStoreDetail } from '../../../records/store/notes/records-notes.actions';
import { OfflineRecordsService } from '../../services/offline.service';
import { OnlineRecordsService } from '../../services/online.service';
import { RecordsFetchAll } from '../../store/all/records-all.actions';
import { RecordsFetchDetail, RecordsClearDetail } from '../../store/detail/records-detail.actions';
import { RecordsFetchSubNavigation, RecordsClearSubNavigation } from '../../store/sub-navigation/records-sub-navigation.actions';
import { selectRecordsAll } from '../../store/all/records-all.selectors';
import { selectSearchesOffline } from '../../../shared/store/offline/offline.selectors';
import { selectSubNavigation } from '../../store/sub-navigation/records-sub-navigation.selectors';
import { selectUserInfo } from '../../../user/store/info/info.selectors';
import { selectUserPreferences } from '../../../user/store/preferences/preferences.selectors';
import { TagsUpdate } from '../../store/tags/records-tags.actions';
import { WindowService } from '../../../shared/services/window.service';
import {
	selectCopySection,
	selectFullRecord,
	selectRecord,
	selectRecordError,
	selectRecordGeneralSection,
	selectRecordOtherSections,
} from '../../store/detail/records-detail.selectors';
import { LOADING_ICON_SIZE } from '../../../shared/components/loading/loading.const';

@Component({
	templateUrl: './detail.page.html',
	styleUrls: ['./detail.page.scss'],
})
export class RecordDetailPage implements OnInit, OnDestroy {
	public copySection: IRecordSection = null;
	public detail: IRecordDetail;
	public error = false;
	public fieldNames = FieldNames;
	public fullRecord: IFullRecord = null;
	public generalSection: IRecordSection = null;
	public isSwipedLeft = false;
	public isSwipedRight = false;
	public loaderSizes = LOADING_ICON_SIZE;
	public loading = false;
	public nextRecord: string = null;
	public notes: INotes = [];
	public notesLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public onDestroy$: Subject<boolean> = new Subject<boolean>();
	public otherSections: IRecordSection[] = [];
	public otherTags: ITag[];
	public previousRecord: string = null;
	public records: IRecord[];
	public searchOrderId: string;
	public searchTitle: string = null;
	public showNotesPopup = false;
	public tag: ITag;
	public tagOptions: ITagLegend[];

	private contactId: number;
	private contactInitials: string;
	private filters = null;
	private isSwiping = false;
	private newRecord = null;
	private offline = false;
	private offlineSearch: IOfflineSearch;
	private quickNotes: INotesSaved[];
	private tagLegend: string;
	private queryParams: {
		sectionId?: number,
		filter?: FilterValues,
	} = {};

	constructor(
		private location: Location,
		private offlineRecordsService: OfflineRecordsService,
		private onlineRecordsService: OnlineRecordsService,
		private route: ActivatedRoute,
		private router: Router,
		private store: Store<IRecordsState>,
		private windowService: WindowService,
	) {
		this.store.select(selectFullRecord)
			.pipe(
				takeUntil(this.onDestroy$),
				filter((fullRecord: IFullRecord) => !!fullRecord)
			)
			.subscribe((fullRecord: IFullRecord) => {
				this.loading = false;
				this.fullRecord = fullRecord;

				this.getOnlineData()
					.then(() => {
						this.resetSwipe();
						this.getSurroundingRecords(this.newRecord);
					});

				this.location.go(`/inbox/${this.searchOrderId}/${this.fullRecord.srRecordId}`);
			});

		this.store.select(selectRecord)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((record: IRecordDetail) => {
				this.notesLoading$.next(false);
				this.notes = record.notes;
				this.tag = record.tag;
				this.otherTags = record.otherTags;
			});

		this.store.select(selectRecordsAll)
			.pipe(
				takeUntil(this.onDestroy$),
				filter((records: any) => records.records && this.fullRecord && this.filters)
			)
			.subscribe(() => this.getBrowseList(
				propOr(null, 'srRecordId', this.fullRecord),
				propOr(null, 'subsectionNameId', this.fullRecord),
				propOr(null, 'value', this.filters.find(filterInner => filterInner.key === 'filter')),
			));

		this.store.select(selectSubNavigation)
			.pipe(
				takeUntil(this.onDestroy$),
				filter((search: ISubNavigation) => !isNil(search))
			)
			.subscribe((search: ISubNavigation) => this.searchTitle = propOr('', 'reportTitle', search));

		this.store.select(selectRecordError)
			.pipe(
				takeUntil(this.onDestroy$),
				filter((error: IResponseError) => !isNil(error))
			).subscribe(() => {
				this.loading = false;
				this.error = true;
			});

		this.store.select(selectRecordGeneralSection)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((section: IRecordSection) => this.generalSection = section);

		this.store.select(selectRecordOtherSections)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((sections: IRecordSection[]) => this.otherSections = sections);

		this.store.select(selectCopySection)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((section: IRecordSection) => this.copySection = section);

		this.store.select<IUserPreferences>(selectUserPreferences)
			.pipe(
				filter((preferences: IUserPreferences) => !isNil(preferences)),
				takeUntil(this.onDestroy$)
			)
			.subscribe((preferences: IUserPreferences) => {
				this.quickNotes = preferences.savedNotes;
				this.contactInitials = preferences.contactInitials;
				this.tagLegend = preferences.tagSettings.tagLegend;
				this.tagOptions = preferences.tagSettings.tags;
			});

		this.store.select<IUserInfo>(selectUserInfo)
			.pipe(
				filter((info: IUserInfo) => !isNil(info)),
				takeUntil(this.onDestroy$)
			)
			.subscribe((info: IUserInfo) => this.contactId = info.contactId);

		this.store.select<IOfflineSearch>(selectSearchesOffline)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((offlineSearch: IOfflineSearch) => this.offlineSearch = offlineSearch);
	 }

	public ngOnInit(): void {
		this.route.params
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((params: Params) => {
				this.loading = true;
				this.searchOrderId = params.search;
				this.offline = this.offlineSearch && this.offlineSearch.searchOrderId.toString() === this.searchOrderId;

				if (this.offline) {
					this.getOfflineData(params.record);
					this.searchTitle = pathOr('', ['search', 'searchedTrademark'], this.offlineSearch);
				} else {
					this.store.dispatch(new RecordsFetchSubNavigation({ searchOrderId: params.search }));
					this.store.dispatch(new RecordsFetchDetail({
						searchOrderId: params.search,
						recordIds: [params.record],
						isOffline: false,
					}));
				}
			});
	}

	public ngOnDestroy(): void {
		this.store.dispatch(new RecordsClearDetail());
		this.store.dispatch(new RecordsClearSubNavigation());
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	public navigate(): void {
		this.router.navigate(['inbox', this.searchOrderId], {
			queryParams: this.queryParams,
		});
	}

	public onSaveNotes(formValue: Array<string | boolean>): void {
		const noteList: INotes
			= NotesService.mapNotes(formValue, this.quickNotes, this.fullRecord.srRecordId, this.contactInitials, this.contactId.toString());

		if (this.offline) {
			this.offlineRecordsService.addNotes(noteList)
				.then(() => this.notes = [ ...this.notes, ...noteList ]);
		} else {
			this.notesLoading$.next(true);
			this.store.dispatch(new NotesStoreDetail({
				searchOrderId: Number(this.searchOrderId),
				noteList,
			}));
		}
	}

	public onCloseNotes(): void {
		this.windowService.toggleBodyScroll();
		this.showNotesPopup = false;
	}

	public showNotes(): void {
		this.windowService.toggleBodyScroll();
		this.showNotesPopup = true;
	}

	public onUpdateTags(updateTag: IUpdateTag): void {
		const select = updateTag.select;
		const tag = {
			recordId: this.fullRecord.srRecordId,
			tagOption: select ? this.tagOptions[updateTag.id].tagOption.toString() : null,
			tagValue: select ? this.tagOptions[updateTag.id].tagValue : null,
			tagLegend: select ? this.tagLegend : null,
			contactInitials: this.contactInitials,
			contactId: this.contactId.toString(),
		};

		if (this.offline) {
			this.offlineRecordsService.updateTag(this.fullRecord.srRecordId, tag, select);
		} else {
			this.store.dispatch(new TagsUpdate({
				searchOrderId: Number(this.searchOrderId),
				tagList: [tag]
			}));
		}
	}

	public handleSwipe(previous: boolean): void {
		if (this.previousRecord === null && this.nextRecord === null) {
			return;
		}

		this.windowService.resetDivScroll('.u-container');

		if (!this.isSwiping) {
			this.isSwiping = true;
			this.setNewRecord(previous);

			// Retrieve new record and notes
			if (this.offline) {
				return this.getNewRecord();
			}

			this.store.dispatch(new RecordsFetchDetail({
				searchOrderId: this.searchOrderId,
				recordIds: [this.newRecord],
				isOffline: false,
			}));
		}
	}

	private async getOnlineData(): Promise<boolean | void> {
		this.filters = await this.onlineRecordsService.getFilters();

		const searchOrderFilter = this.filters.find((filterInner: IRecordFilter) => filterInner.key === 'searchOrderId');

		if (this.searchOrderId !== propOr(null, 'value', searchOrderFilter)) {
			this.store.dispatch(new RecordsFetchAll({
				filterBy: [{
					filterName: 'RecordFilter',
					filterValue: FilterValues.AL,
				}],
				isIncludeSections: true,
				isOffline: true,
				searchOrderId: this.searchOrderId,
				subSectionNameID: null,
			}));

			return Promise.reject();
		}

		const currentFilter = this.filters.find((filterInner: IRecordFilter) => filterInner.key === 'filter');
		const currentFilterValue = propOr(null, 'value', currentFilter);
		const recordId = propOr(null, 'srRecordId', this.fullRecord);
		const sectionId = propOr(null, 'subsectionNameId', this.fullRecord);

		this.queryParams = {
			sectionId,
			filter: currentFilterValue,
		};

		// Get online browselist
		return this.getBrowseList(recordId, sectionId, currentFilterValue);
	}

	private getOfflineData(recordId: string): void {
		Promise
			.all([
				this.offlineRecordsService.getRecord(recordId),
				this.offlineRecordsService.getNotes(recordId),
				this.offlineRecordsService.getFilters(),
			])
			.then(([record, notes, filters]: [IRecordDetail, INotes, IRecordFilter[]]) => {
				const sectionId = pathOr(null, ['fullRecord', 'subsectionNameId'], record);
				const currentFilter = filters.find((filterObject: IRecordFilter) => filterObject.key === 'filter');
				const currentFilterValue = propOr(null, 'value', currentFilter);

				this.processRecord(record, notes);

				// Get offline browselist
				this.getBrowseList(recordId, sectionId, currentFilterValue, false);

				this.queryParams = {
					sectionId,
					filter: currentFilterValue,
				};
			});
	}

	private getSurroundingRecords(recordId: string): void {
		if (isNil(recordId)) {
			return;
		}

		const currentRecord = this.records.find(recordResult => recordResult.srRecordID === recordId);
		const currentIndex = this.records.indexOf(currentRecord);

		if (this.records.length === 1) {
			this.previousRecord = null;
			this.nextRecord = null;

			return;
		}

		if (currentIndex === 0) {
			this.previousRecord = this.records[this.records.length - 1].srRecordID;
			this.nextRecord = this.records[currentIndex + 1].srRecordID;

			return;
		}

		if (currentIndex === this.records.length - 1) {
			this.previousRecord = this.records[currentIndex - 1].srRecordID;
			this.nextRecord = this.records[0].srRecordID;

			return;
		}

		this.previousRecord = this.records[currentIndex - 1].srRecordID;
		this.nextRecord = this.records[currentIndex + 1].srRecordID;
	}

	private getBrowseList(recordId: string, sectionId: number, filterValue: string, online = true): Promise<boolean | void> {
		if (isNil(sectionId) || isNil(filterValue)) {
			return;
		}

		return (online ? this.onlineRecordsService : this.offlineRecordsService).getRecords(sectionId, filterValue)
			.then((records: IRecord[]) => {
				this.records = records;
				this.loading = false;

				this.getSurroundingRecords(recordId);
			});
	}

	private setNewRecord(previous = true): void {
		this.isSwipedLeft = !previous;
		this.isSwipedRight = previous;
		this.newRecord = previous ? this.previousRecord : this.nextRecord;
	}

	private resetSwipe(): void {
		this.isSwipedRight = false;
		this.isSwipedLeft = false;
		this.isSwiping = false;
	}

	private getNewRecord(): void {
		Promise
			.all([
				this.offlineRecordsService.getRecord(this.newRecord),
				this.offlineRecordsService.getNotes(this.newRecord),
			])
			.then(([record, notes]: [IRecordDetail, INotes]) => {
				if (isNil(record)) {
					return;
				}

				setTimeout(() => {  // this timeout needs to exist to mimick the swiping effect
					this.processRecord(record, notes);

					// Reverse animation without transition so user thinks it has slided
					this.resetSwipe();
				}, 400);

				// Calculate previous and next record
				this.getSurroundingRecords(this.newRecord);
			});
	}

	private processRecord(record: IRecordDetail, notes: INotes): void {
		const sections: IRecordSection[] = [...record.fullRecord.sections];

		// Update detail data
		this.detail = record;
		this.fullRecord = record.fullRecord;
		this.otherTags = record.otherTags;
		this.tag = record.tag;
		this.notes = notes;
		this.generalSection = propOr(null, 0, sections);
		this.otherSections = drop(1, sections).filter((section: IRecordSection) => section.id !== 'secCopy');
		this.copySection = drop(1, sections).find((section: IRecordSection) => section.id === 'secCopy');

		this.location.go(`/inbox/${this.searchOrderId}/${this.fullRecord.srRecordId}`);
	}
}
